import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios'
import ModalEdit from '../ModalEdit';
import ModalNewRoles from './ModalNewRoles';
import { HeaderTable } from '../HeaderTable';
const GET_JOBS_URL = 'admin/setting/job'

const Roles = () => {
  const [modalIsOpen, setModalIsOpen] = useState("");
  const [id, setId] = useState("");
  const [listClass, setlistClass] = useState([])
  const [loading, setloading] = useState(true)

  const handleColse = () => {
    setModalIsOpen("")
  }

  useEffect(() => {
    getJobs()
  }, [])








  const handleDeteleJob = async () => {

    await axios.delete(`${GET_JOBS_URL}/${id.id}`).then(response => {
      if (response.status == 200) {
        handleColse()
        getJobs()
        console.log("ok")
      }
    })
  }


  const getJobs = async () => {

    await axios.get(GET_JOBS_URL).then(response => {
      setlistClass(response?.data)
    })
    setloading(false)
  }




  const columns = [
    {
      name: "פעולה",

      cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
        setId(row)
        setModalIsOpen("Edit")
      }}><BsPencil /></button>
        <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
          setId(row)
          setModalIsOpen("Delete")
        }
        }><IoTrashOutline /></button> </div>,
      style: {
        fontSize: '16px',
        justifyContent: 'center',

      },
      grow: 1,


    },
    {
      name: "תיאור תפקיד",
      grow: 6,
      selector: (row) => row.description,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
       
    },



  ];



  const customStyles = {
    header: {
      style: {

        justifyContent: 'center',

      },
    },
    id: {
      style: {
        '&:2': { backgroundColor: '#EEEEEE' },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        // borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {

        justifyContent: 'center',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        // borderRightColor: defaultThemes.default.divider.default,

      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {

          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFFFFF',
        '&:active': {
          backgroundColor: '#EEEEEE',
        },
      },
    },
  }


  useEffect(() => {
    if (modalIsOpen) {
        document.body.style.overflow = 'hidden'; // Disable body scrolling
      } else {
        document.body.style.overflow = 'auto'; // Enable body scrolling
      }
  
      return () => {
        document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
      };
    // setHide(modalIsOpen)
}, [modalIsOpen])




  return (
    <div className='w-full p-3'>
      <HeaderTable active={"תפקידים"} setModalIsOpen={setModalIsOpen} />

      <DataTable columns={columns} data={listClass} pagination customStyles={customStyles} progressPending={loading} className='border' >

      </DataTable>
      {modalIsOpen == "New" && <ModalNewRoles showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getJobs}>
      </ModalNewRoles>}
      {modalIsOpen == "Edit" && <ModalNewRoles showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getJobs} row={id}>
      </ModalNewRoles>}
      {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDeteleJob}></ModalEdit>}
    </div>
  )
}

export default Roles