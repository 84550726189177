import React, { useState, useEffect } from 'react'
import TrainingDay from './TrainingDay'
import axios from 'axios'
import { BiError } from "react-icons/bi";
import { IconContext } from "react-icons";
import { useNavigate, useParams } from 'react-router-dom';
import ModalInfoTrainingDay from './ModalInfoTrainingDay';
const GET_URL = 'dashboard/member/open_qualification_day_register'

const ModalMyTrainingDays = ({ setModalIsOpen,handleColse, corseId ,getTrainingDaysMain}) => {
    // const { corseId } = useParams();
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [fetchData, setFetchData] = useState([])
    const [show, setShow] = useState(false)
    const navigator = useNavigate()
    const getTrainingDays = async () => {

        // if (!corseId)
        //     await axios.get(GET_URL, {
        //         // params: {
        //         //     "page": page
        //         //     , "size": perPage,
        //         // }
        //     })
        //         .then(response => {
        //             if (response.status == 200) {
        //                 setFetchData(response?.data)
        //             }
        //         })
        // else
        // {
        await axios.get(GET_URL, {
            params: {
                "qId": corseId
                //     , "size": perPage,
            }
        }).then(response => {
            if (response.status == 200) {
                setFetchData(response?.data)
                setShow(true)
            }
        })
    }


    // }

    useEffect(() => {
        getTrainingDays()
    }, [])
    // useEffect(() => {
    //     getTrainingDays()
    // }, [])
    return (
        <div className={`absolute top-0  h-screen z-10 left-0 flex flex-col items-center  w-full  bg-[#FDFFFC]/50`}
            onClick={() => {
                // handleColse()
            }}>
            <div className='w-full min-h-screen overflow-y-auto flex flex-col items-center pt-2'>
                <div className={`bg-white w-3/4  rounded-lg   divide-slate-500 border-0 drop-shadow-2xl shadow-2xl border-emerald-50  `}>
                    <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2 '>
                        <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200'
                            onClick={() => {
                                // if(fetchData.length == 0 && corseId)
                                navigator("/registrationTrainingDays")
                                // setModalIsOpen("")
                                handleColse()
                            }} >x</button>
                    </div>
                    {show && fetchData.length == 0 && corseId &&
                        <IconContext.Provider value={{ size: "30px" }} >
                            <div className=' flex justify-center items-center h-32 '>
                                <BiError />
                                <span className='bg-red-500 p-2 rounded-md'>קורס לא קיים</span>
                            </div>
                        </IconContext.Provider>}
                    {!fetchData.length == 0 && fetchData?.map(course => { return <TrainingDay course={course} key={course.id} onOpenModal={() => setSelectedCourse(course)} /> })}

                </div>
            </div>
            {selectedCourse && (
                <ModalInfoTrainingDay course={selectedCourse} setModalIsOpen={() => setSelectedCourse(null)} handleColse={handleColse} getTrainingDaysMain={getTrainingDaysMain} />
            )}
        </div>
    )
}

export default ModalMyTrainingDays