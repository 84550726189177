
import React, { useState } from "react";
import "./PaymentModal.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PaymentModal = ({onBack,idCorse,onNext,onError,getTrainingDays,setErrMess,course}) => {
   const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState("כרטיס אשראי");
    const [agree, setAgree] = useState(false);
    const Url=`dashboard/member/request_register_qualification/${idCorse}/SELF`
    const Url1=`dashboard/member/request_register_qualification/${idCorse}/MEMBER_FRIEND`
    const OverToPay=async()=>{
        
      await axios.post(`${Url}`, {
              headers: { 'Content-Type': 'application/json' }
          }).then(response => {
              if (response?.status == 200) {
                const data = response?.data;
                if (typeof data === 'string') {
                  // אם התגובה היא הודעה (string)
                  getTrainingDays()
                  onNext(data)
                  // alert("Message:", data);
                  // getTrainingDaysMain()
                  // handleColse()
              } else {
                  // אם התגובה היא אובייקט (כמו SendProfileIdDto)
                  // alert("Object:", data);
            
                  // setnamecalss("")
                  // console.log(response)
                  // seturlCardCom(response?.data?.urlPage)
                  // setModalConfirmIsOpen(true)
                  window.location.href =response?.data?.urlPage                   
              }}
               else {
                
                onError(response.response?.data?.message)
                // console.log(response)
              }
          })
          .catch (error=> {
            getTrainingDays()
            onError(error?.response?.data?.message)
            
          })
  }
    const OverToPayMember=async()=>{
        
      await axios.post(`${Url1}`, {
              headers: { 'Content-Type': 'application/json' }
          }).then(response => {
              if (response?.status == 200) {
                const data = response?.data;
                  // setnamecalss("")
                  // console.log(response)
                  // seturlCardCom(response?.data?.urlPage)
                  // setModalConfirmIsOpen(true)
                  getTrainingDays()
                  onNext(data)
                  // onNext()
                  // window.location.href =response?.data?.urlPage                   
              }
              else
              {
                
                onError(response.response?.data?.message)
                // console.log(response)
              }
          }).catch (error=> {
                
            onError(error?.response?.data?.message)
            
          })
  }
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          {/* כפתור חזור */}
          <button className="back-btn" onClick={onBack}>
            ←
          </button>
  
          {/* פרטי הקורס */}
          <div className="course-info ">
            <img
            
            src={course?.mySelfQualificationDto?.linkImage?`https://archivebureau.s3.eu-west-3.amazonaws.com/${course?.mySelfQualificationDto?.linkImage}` :"https://images.pexels.com/photos/4391470/pexels-photo-4391470.jpeg"}
              alt="קורס"
              className="course-image"
            />
            <div className="flex flex-col">
              <h4 className="text-right">{course?.mySelfQualificationDto?.name}</h4>
              <p className="text-left">₪{course?.product?.price}</p>
            </div>
          </div>
  
 

          {/* בחירת אמצעי תשלום */}
          <div className="flex flex-col items-center p-2 gap-2 border rounded-2xl ">      
                <p className="text-right w-full py-1">בחירת אמצעי תשלום:</p>
          <div className="payment-options">
            <label>
              <input
                type="radio"
                name="payment"
                value="כרטיס אשראי"
                checked={paymentMethod === "כרטיס אשראי"}
                onChange={() => setPaymentMethod("כרטיס אשראי")}
              />
              כרטיס אשראי
            </label>
            <label>
              <input
                type="radio"
                name="payment"
                value="לקוח משלם"
                checked={paymentMethod === "לקוח משלם"}
                onChange={() => setPaymentMethod("לקוח משלם")}
              />
              לקוח משלם
            </label>
          </div>
  
          {/* אישור תנאים */}
          {paymentMethod==="לקוח משלם"&&<div className="terms">
            <input
              type="checkbox"
              checked={agree}
              onChange={() => setAgree(!agree)}
            />
            <p className="text-right">
              אני מאשר כי אני מודע לתנאים ולהתחייבויות הקורס ולמדיניות האתר.
            </p>
          </div>}
          </div>

  
          {/* כפתור תשלום */}
          { paymentMethod==="לקוח משלם"&& <button className="pay-btn" disabled={!agree}  onClick={ 
                        OverToPayMember}>
            שלם
          </button>}
          { paymentMethod==="כרטיס אשראי"&& <button className="pay-btn"  onClick={ 
                        OverToPay}>
            שלם
          </button>}
        </div>
      </div>
    );
}

export default PaymentModal