import React, { useEffect, useState } from 'react'
// import homeImage from '../assets/image_home_Page.jpg'
import homeImage from '../assets/HOMEimage.webp'
import { useNavigate } from 'react-router-dom';
// import homeImage from '../assets/image_home_Page.png'


const HomePage = () => {
  const navigate = useNavigate()
  const [isCheckingDevice, setIsCheckingDevice] = useState(true); // State לבדיקת המכשיר

  useEffect(() => {
   
    // בדיקה האם מדובר במכשיר מובייל
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // בדיקת הנתיב הנוכחי כדי למנוע לולאת ניתוב
    if (isMobile && window.location.pathname !== '/Mobile') {
      // console.log("מכשיר מובייל זוהה, מנתב לגרסה מובייל...");
      navigate('/Mobile');
    } else if (!isMobile && window.location.pathname !== '/') {
      // console.log("מחשב שולחני זוהה, מנתב לגרסת דסקטופ...");
      navigate('/');
    }
    setIsCheckingDevice(false); // סיום הבדיקה
  }, [navigate]);

  if (isCheckingDevice) {
    return null; // או הצגת Loader אם רוצים
  }
  
    return (
    <div className='bg-[#f3f3f3] p-4 ' >
        <div className='bg-white w-full p-4 flex justify-center '>
                <img src={homeImage} alt='image_home_Page' className=" max-w-[400px] w-[70%] "/>

        </div>
        

    </div>
  )
}

export default HomePage