import React, { useState } from 'react'
import ModalConfirm from './ModalConfirm';


const ModalInfoTrainingDay = ({course,setModalIsOpen,handleColse,getTrainingDaysMain}) => {
    const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);  
  return (
    <div className='fixed top-0 min-h-screen z-20 left-0 flex flex-col items-center   pt-2 w-full  bg-[#FDFFFC]/50 '
    onClick={() => {
        // handleColse()
    }}
    style={{ direction: "rtl" }}>
    <div  className='bg-white w-[50%]   rounded-lg text-right    divide-y-2 divide-slate-500 border-0 drop-shadow-2xl shadow-2xl border-emerald-50 '>
        <div className='w-full bg-[#0d406b] rounded-t-md flex  border-[#0d406b] border-2'>
            <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {setModalIsOpen(false)
            //   
            }} >x</button>
        </div>
        
        
            <div className='p-3  ' >
                <div className='grid grid-cols-4 ' >
                    <div className='grid col-span-1 ml-2 text-sm  font-bold'>מספר אשכול</div>
                    <div className='grid col-span-3'>{course?.mySelfQualificationDto?.cluster?.number}</div>
                </div>
                <div className='grid grid-cols-4 '>
                    <div className='grid col-span-1 ml-2 text-sm  font-bold'>שם האשכול</div>
                    <div className='grid col-span-3'>{course?.mySelfQualificationDto?.cluster?.name}</div>
                </div>
                <div className='grid grid-cols-4 '>
                    <div className='ml-2 text-sm  font-bold'>מספר יום כשירות</div>
                    <div>{course?.mySelfQualificationDto?.number}</div>
                </div>
                <div className='grid grid-cols-4 '>
                    <div className='ml-2 text-sm  font-bold'>שם יום הכשירות</div>
                    <div className='grid col-span-3'> {course?.mySelfQualificationDto?.name}</div>
                </div>
                <div className='grid grid-cols-4 '>
                    <div className='ml-2 text-sm  font-bold'>תיאור</div>
                    <div className='grid col-span-3'> {course?.mySelfQualificationDto?.description}</div>
                </div >
                <div className='grid grid-cols-4 '>
                    <div className='ml-2 text-sm  font-bold'>מיקום</div>
                    <div className='grid col-span-3'> {course?.mySelfQualificationDto?.place}</div>
                </div>
                <p className='text-lg font-medium underline'>
                פרטי המדריך
                </p>
                <div className='grid grid-cols-4 '>
                    <div className='ml-2 text-sm  font-bold'>שם מלא</div>
                    <div className='grid col-span-3'>{course?.mySelfQualificationDto?.instructor?.name}</div>
                </div>
                <div className='grid grid-cols-4 '>
                    <div className='ml-2 text-sm  font-bold'>טלפון</div>
                    <div className='grid col-span-3'>{course?.mySelfQualificationDto?.instructor?.phone}</div>
                </div>

            </div>
            <div className='flex justify-between text-white p-2'>
                <button className='bg-blue-900 text-xl p-2 rounded-md' onClick={() => {setModalIsOpen(false)}}>סגור</button>
                <button className='bg-[#1e8e2b] text-xl p-2 rounded-md' onClick={() => {setModalConfirmIsOpen(true)}}>הירשם עכשיו</button> 
        </div>
    </div>
    {modalConfirmIsOpen&&<ModalConfirm idCorse={course?.mySelfQualificationDto?.id} handleColse={handleColse} setModalIsOpen={setModalConfirmIsOpen} getTrainingDaysMain={getTrainingDaysMain}></ModalConfirm>}
</div>
  )
}

export default ModalInfoTrainingDay