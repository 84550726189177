import React, { useState } from 'react'
import MoreOptionsCard from '../../../../../ui/MoreOptionsCard'

import { BsPencil } from 'react-icons/bs';
import { IoTrashOutline } from 'react-icons/io5';
import { MdAssignment } from 'react-icons/md';
import { formatOnlyTime, convertToIsraelTime } from '../../../../../../util/formatsForDate';
import ReDataTable from '../../../../../ui/ReDataTable';
import axios from 'axios';
import { HeaderTable } from '../../../../../HeaderTable';
import NewSignature_ActiviyDay from './NewSignature_ActiviyDay';
import ModalEdit from '../../../../../ModalEdit';

const Signature_windows_ActiviyDay = ({TrainingDays,row,handleColse,qualificationName}) => {
 const [modalIsOpen, setModalIsOpen] = useState("");
  const [id, setId] = useState("");
  const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);

  const GET_URL = `admin/qualification/activity_day/${TrainingDays}/${row}`

  const handleDetele = async () => {

    await axios.delete(`admin/qualification/signature_windows/${row}/${id.id}`).then(response => {
      if (response.status == 200) {
        getDataTable()
        handleColseSign()

        console.log("ok")
      }
      else {
        console.log("error")
      }
    })
  }
  const handleColseSign = () => {
    setModalIsOpen("")
}
    const getDataTable = async () => {
        const response = await axios.get(GET_URL, {
        })
        if (response.status == "200") {
            setFetchData(response?.data)
          
        }
    
        // setFetchData(result1);
        // setTotalRows(result2);
        setloading(false)
      };
   

 const columns = [
    
    {
      name: "פעולות",
    //   grow: 2,
      cell: row => <div className='w-full'>
       
        <button title="מחיקה" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
          setId(row)
          setModalIsOpen("Delete")
        }}><IoTrashOutline /></button>
        
        <button className='  p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
          // setnamecalss(row?.description)
          // setAction("edit")
          setId(row)
          console.log(row)
          setModalIsOpen("Edit")
        }
        }><BsPencil /></button>
      </div>,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },

    }
    ,

    {
      name: "שעת סיום",
      grow: 2,
      selector: (row) => row?.timeEnd ? formatOnlyTime(row?.timeEnd) : "",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },
     
    },
    {
      name: "שעת התחלה",
      grow: 2,
      selector: (row) => row?.timeStart ? formatOnlyTime(row?.timeStart) : "",
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
      
    },
    




  ];



    
  return (
    <MoreOptionsCard handleColse={handleColse}>
         
         <div className='bg-[#f3f3f3] p-4 'style={{ direction: "ltr" }}>  
      <HeaderTable active={"חלונות חתימה ביום כשירות"} setModalIsOpen={setModalIsOpen} howmach={qualificationName}/>
      <ReDataTable columns={columns} getDataTable={getDataTable} fetchData={fetchData?.signatureWindows} loading={loading} totalRows={totalRows} />
      {modalIsOpen == "New" && <NewSignature_ActiviyDay showModal={modalIsOpen == "New"} GET_URL={GET_URL} handleColse={handleColseSign} getData={getDataTable} fetchData={fetchData} />}
      {modalIsOpen == "Edit" && <NewSignature_ActiviyDay showModal={modalIsOpen == "Edit"} GET_URL={GET_URL} handleColse={handleColseSign} getData={getDataTable} row={id} fetchData={fetchData}/>}
      {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColseSign} handleDetele={handleDetele}></ModalEdit>}
    </div>
    </MoreOptionsCard>
  )
}

export default Signature_windows_ActiviyDay