import React, { useState } from 'react'
import MoreOptionsCard from '../../../../ui/MoreOptionsCard'
import { HeaderTable } from '../../../../HeaderTable'
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { formatOnlyTime } from '../../../../../util/formatsForDate';
import axios from 'axios';
import ModalEdit from '../../../../ModalEdit';

const Adding_Manual_Signature = ({handleColse,fetchData,getDataTable,id}) => {
      const GET_URL_ADD = `/admin/qualification/manual_signature_member_qualification/${id}/${fetchData?.id}`
      const [idr, setId] = useState("");
      const [modalIsOpen, setModalIsOpen] = useState("");
    // console.log(fetchData)
    
    
    // const handleAddSignature = async () => {

    //     await axios.get(`${GET_URL_ADD}`).then(response => {
    //       if (response.status == 200) {
           
    //         // handleColse()
    //         getDataTable()
    //         // getPaying_customers(currentPage - 1)
    //         console.log("ok")
    //       }
    //       else {
    //         console.log("error")
    //       }
    //     })
    //   }
    const handleDetele = async () => {
      // console.log(`${GET_DELETE}/${id.id}`)
      // await axios.delete(`${GET_DELETE}/${id.id}`).then(response => {
          
      //     if (response.status == 200) {
      //         handleColse()
      //         getDataTable(currentPage-1)
      //         // getDFilter()
      //         console.log("ok")
      //     }
      //     else {
      //         console.log("error")
      //     }
      // })
      try {
        // console.log(id)
        // console.log(idr)
        // console.log(`admin/qualification/detach_signature_member_qualification/${id}/${idr.id}/${idr.type}`)
        const response = await axios.get(`admin/qualification/detach_signature_member_qualification/${id}/${idr.id}/${idr.type}`);
        if (response.status === 200) {
            console.log("חתימה נוספה בהצלחה");
            getDataTable(); // ריענון הרשימה
            handleColse()
        } else {
            console.log("שגיאה בהוספת חתימה");
        }
    } catch (error) {
        console.error("שגיאה בבקשת API:", error);
    }
  }



    const handleAddSignature = async () => {
    try {
        const response = await axios.get(GET_URL_ADD);
        if (response.status === 200) {
            console.log("חתימה נוספה בהצלחה");
            getDataTable(); // ריענון הרשימה
            handleColse()
        } else {
            console.log("שגיאה בהוספת חתימה");
        }
    } catch (error) {
        console.error("שגיאה בבקשת API:", error);
    }
};
    const columns = [
    
        {
          name: "פעולות",
          grow: 2,
          cell: (row) => <div className='w-full'>
            <button title="מחיקה" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
              setId(row)
              setModalIsOpen("Delete")
            }}><IoTrashOutline /></button>
    
            {/* <button className='  p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
              // setnamecalss(row?.description)
              // setAction("edit")
              setId(row)
              console.log(row)
              setModalIsOpen("Edit")
            }
            }><BsPencil /></button> */}
          </div>,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
    
        }
        ,
    
        {
          name: "חתימה",
          grow: 2,
          cell: (row) => <img className='h-20' src={row?.linkSignature?`https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.linkSignature}`:""}/>,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
        },
        {
          name: "מרחק (מטרים)",
          grow: 2,
          cell: (row) => row?.distance,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
        },
        {
          name: "Latitude",
          grow: 2,
          cell: (row) => row?.type=="auto"?row?.latitude:"חתימה ידנית" ,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },

        },
        {
          name: "Longitude",
          grow: 2,
          cell: (row) => row?.type=="auto"?row?.longitude:"חתימה ידנית",
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
        },
        {
          name: "שעה",
          grow: 2,
          cell: (row) => row?.timeSignature?formatOnlyTime(row?.timeSignature):"",
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
          
        },
    
    
    
    
      ];
      const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        // // headRow: {
        // //     style: {
        // //         justifyContent: 'center',
        // //         borderTopStyle: 'solid',
        // //         borderTopWidth: '1px',
        // //         // borderTopColor: defaultThemes.default.divider.default,
        // //     },
        // },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
                justifyContent: 'center',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }
   
  return (
    <MoreOptionsCard handleColse={handleColse}>
       
       <div className='bg-[#f3f3f3] p-4 'style={{ direction: "ltr" }}>
      <HeaderTable active={"חתימות נוכחות"} titleBtn="חתימה ידנית" setModalIsOpen={handleAddSignature}/>
      <DataTable columns={columns} data={fetchData?.numberSignatures} pagination customStyles={customStyles} />
      {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}

    </div>
    </MoreOptionsCard>
  )
}

export default Adding_Manual_Signature