import React, { useState } from "react";
import { FaDownload, FaChevronDown, FaInfoCircle, FaTimes } from "react-icons/fa";
import Cancel from './Cancel.jsx'
const CourseStatusCard = ({ courseName, date, status,courseId,ifcancel, certificateUrl,handleDetele }) => {
  const [show,setShow]=useState(false)
  const getStatusColor = (status) => {
    switch (status) {
      case "ברישום":
        return "bg-orange-500";
      case "בוטל":
        return "bg-red-500";
      case "הסתיים":
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <div className="shadow-lg rounded-lg p-4 border border-gray-200">
      {/* כותרת הכרטיס */}
      <div className="flex justify-end items-center mb-1">
        <h3 className="text-lg font-semibold mr-2">{courseName}</h3>
        <button className="text-gray-500 hover:text-gray-800">
          <FaChevronDown />
        </button>
      </div>

      {/* פרטי הקורס */}
      <div className="flex flex-col gap-2">
        <span className="text-sm text-gray-600">{`תאריך סיום: ${date || ""}`}</span>
        <span className="text-sm text-gray-600">
          סטטוס: <span className={`px-2 py-1 text-white rounded ${getStatusColor(status)}`}>{status}</span>
        </span>
      </div>

      {/* כפתורים לפעולות */}
      <div className="flex justify-around mt-4 gap-2">


         {/* כפתור הורדת תעודה */}
         <a
          href={status === "הסתיים" && certificateUrl ? `https://archivebureau.s3.eu-west-3.amazonaws.com/${certificateUrl}` : "#"}
          download
          className={`flex items-center gap-2 px-4 py-2 rounded transition ${
            status === "הסתיים" && certificateUrl ? "bg-blue-600 text-white hover:bg-blue-800" : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
          onClick={(e) => {
            if (!(status === "הסתיים" && certificateUrl)) e.preventDefault();
          }}
        >
          <FaDownload />
          הורדת תעודה
        </a>
       
        {/* כפתור ביטול קורס */}
        <button
          className={`flex items-center gap-2 px-4 py-2 rounded transition ${
            status === "ברישום" && ifcancel ? "bg-red-500 text-white hover:bg-red-700" : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
          onClick={()=>setShow(true)}
          disabled={status !== "ברישום" || !ifcancel }
        >
          <FaTimes />
          ביטול קורס
        </button>
         {/* כפתור אינפורמציה */}
         <button className="flex items-center gap-2 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition">
          <FaInfoCircle />
          מידע
        </button>


       
      </div>
      {show&&<Cancel handleColse={()=>setShow(false)} handleDetele={()=>{handleDetele(courseId)
        setShow(false)
      }}></Cancel>}
      
    </div>
  );
};

export default CourseStatusCard;
