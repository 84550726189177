import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../../Modall';
import Select from "react-select"
import { convertDateFormat, convertDateFormatInputDate } from '../../../util/formatsForDate';

const GET_URL = 'admin/qualification/create_Qualification_day'
const GET_Filter = 'admin/qualification/all_Scroll_before_buildQ'

const ModalNewTrainingDays = ({ showModal, children, handleColse, getDepartment, row = "", getDFilter }) => {
    // const [isChecked, setIsChecked] = useState(false)

    const [previewUrl, setPreviewUrl] = useState('');
    const [formData, setFormData] = useState({
        "clusterId": null,
        "number": "",
        "name": "",
        "productId": null,
        "description": "",
        "startDate": "",
        "lastDateCancellation": "",
        "maximumParticipants": 0,
        "isOnline": false,
        "place": "",
        "latitude": 0,
        "longitude": 0,
        "distanceSignature": 0,
        "statusQualificationId": null,
        "instructorId": null,
        "titleCertificateId": null,
        "days": 0,
        "hours": 0,
        "manualSignatureWindow": true,
        "isLimit": false,
        // qulImage: '',




    });
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState({});

    const handleChangetext = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleChangeData = (e) => {
        const { name, value } = e.target;
        // const date = new Date(value);
        // date.setUTCHours(0, 0, 0, 0);
console.log(value)
        // // Convert to ISO string and replace 'Z' with '+00:00' for the timezone
        // ;
        // setFormData({
        //     ...formData,
        //     [name]: date.toISOString().replace('Z', '+00:00')
        // });
        setFormData({
            ...formData,
            [name]: (value)
            // [name]: convertDateFormat(value)
        });
    };
   
    const checkHandler = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: !formData?.isOnline
        });
    }

    const handleChange = (selectedOption, name,) => {
        console.log(selectedOption)
        setFormData({
            ...formData,
            [name]: selectedOption?.value
            ,
        })
    }

    const validate = () => {
        const newErrors = {};
        // if (!formData.fullname) newErrors.fullname = 'שדה חובה';
        // if (!formData.identity) newErrors.identity = 'שדה חובה';
        // if (!formData.phone) newErrors.phone = 'שדה חובה';
        // if (!formData.urlimage) newErrors.file = 'שדה חובה';


        return newErrors;
    };

    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            console.log(formData)
            addNewTrainingDays()
        }
    };

    // useEffect(() => {
    //     if (row) {
    //         setFormData(
    //             {
    //                 'fullname': row.name,
    //                 'identity': row.identity,
    //                 'phone': row.phone,
    //                 'urlimage': `https://archivebureau.s3.eu-west-3.amazonaws.com/${row.imgSignature}`,
    //             })
    //         setPreviewUrl(`https://archivebureau.s3.eu-west-3.amazonaws.com/${row.imgSignature}`)
    //         // setFile(row.imgSignature)
    //     }
    // }, [])


    const handleFileChange = (event) => {
        // const { name } = event.target;
        const fileInput = event.target;
  const file1 = fileInput.files[0];

      
        if (file1) {
                setFile(file1)      
                // נקה את התמונה הקודמת
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  "urlimage": null, // נקה קודם
                }));
            
                const reader = new FileReader();
            
                reader.onload = (e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    "urlimage": e.target.result, // עדכן את התמונה החדשה
                  }));
                };
            
                reader.readAsDataURL(file1);
                fileInput.value = '';
        }
      };

            //  reader.onload = (e) => {
            //     console.log( e.target.result)
            //     setFormData({
            //         ...formData,
            //         'urlimage': e.target.result
            //     });;
            //     reader.readAsDataURL(file1);
            // };

            // Extracting only the file name
            //   setFormData({
            //     ...formData,
            //     [name]: event.target.result
            // });
            //   setImageFileName(file.name);
     

    // useEffect(() => { console.log(previewUrl) }, [previewUrl])
    // useEffect(() => { console.log(formData) }, [formData])

    const handleEditClass = async (id) => {
        const formData1 = new FormData();
        formData1.append('qulDetails', JSON.stringify(
            // formData
            {
                "clusterId": formData?.clusterId,
                "number": formData?.number,
                "name": formData?.name,
                "productId": formData?.productId,
                "description": formData?.description,
                "startDate": convertDateFormat(formData?.startDate),
                "lastDateCancellation": convertDateFormat(formData?.lastDateCancellation),
                "maximumParticipants": formData?.maximumParticipants,
                "isOnline": formData?.isOnline,
                "place": formData?.place,
                "latitude": formData?.latitude,
                "longitude": formData?.longitude,
                "distanceSignature": formData?.distanceSignature,
                "statusQualificationId": formData?.statusQualificationId,
                "instructorId": formData?.instructorId,
                "titleCertificateId": formData?.titleCertificateId,
                "days": formData?.days,
                "hours": formData?.hours,
                "manualSignatureWindow": formData.manualSignatureWindow,
                // 'qulImage': file
            }
        ));
        formData1.append('qulImage', file);
        const response = await axios.put(`admin/qualification/edit_Qualification_day/${id}`, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

        if (response?.status == 200) {

            console.log(formData)
            // setnamecalss("")
            getDFilter()
            getDepartment()
            handleColse()
        }
        // const validationErrors = validate();
        // if (Object.keys(validationErrors).length > 0) {
        //     setErrors(validationErrors);
        // } else {
        //     const formData1 = new FormData();
        //     formData1.append('instructorDetails', JSON.stringify({
        //         "name": formData.fullname,
        //         "identity": formData.identity,
        //         "phone": formData.phone
        //     }));
        //     formData1.append('img_Signature', file);
        //     const response = await axios.put(`${GET_URL}/${id}`, formData1
        //         ,
        //         {
        //             headers: { 'Content-Type': 'multipart/form-data' }
        //         })
        //     if (response.status == 200) {
        //         // setnamecalss("")
        //         getDepartment()
        //         handleColse()
        //         console.log("Edit")
        //     }






            // await axios.put(`${GET_URL}/${id}`, JSON.stringify({
            //     "name": formData.name,
            //     "identity": formData.id,
            //     "shippingAddress": formData.shippingAddress,
            //     "postalCode": formData.postcode,
            //     "primaryEmailReceipts": formData.primaryEmailReceipts,
            //     "attendanceBox": 0
            // }), {
            //     headers: { 'Content-Type': 'application/json' }
            // }).then(response => {
            //     if (response.status == 200) {
            //         // setnamecalss("")
            //         getDepartment()
            //         handleColse()
            //         console.log("Edit")
            //     }
            // })
        
    }

    const addNewTrainingDays = async () => {

        const formData1 = new FormData();
        formData1.append('qulDetails', JSON.stringify(
            // formData
            {
                "clusterId": formData?.clusterId,
                "number": formData?.number,
                "name": formData?.name,
                "productId": formData?.productId,
                "description": formData?.description,
                "startDate": convertDateFormat(formData?.startDate),
                "lastDateCancellation": convertDateFormat(formData?.lastDateCancellation),
                "maximumParticipants": formData?.maximumParticipants,
                "isOnline": formData?.isOnline,
                "place": formData?.place,
                "latitude": formData?.latitude,
                "longitude": formData?.longitude,
                "distanceSignature": formData?.distanceSignature,
                "statusQualificationId": formData?.statusQualificationId,
                "instructorId": formData?.instructorId,
                "titleCertificateId": formData?.titleCertificateId,
                "days": formData?.days,
                "hours": formData?.hours,
                "manualSignatureWindow": formData.manualSignatureWindow,
                // 'qulImage': file
            }
        ));
        formData1.append('qulImage', file);
        const response = await axios.post(GET_URL, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })

        if (response?.status == 200) {

            console.log(formData)
            // setnamecalss("")
            getDFilter()
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }
    const [datafiler, setDatafiler] = useState("");

    const getFilter = async () => {

        await axios.get(GET_Filter, {
        })
            .then(response => {
                setDatafiler(response?.data)

            })

    }
    useEffect(() => {
        getFilter()
        if (row)
            axios.get(`admin/qualification/get_qualification/${row?.id}`, {
            },
            //  {headers: {
            //     'Cache-Control': 'no-cache'
            //   }
            // }
        )
                .then(response => {
                    console.log(response)
                    setFormData({
                        "clusterId": response?.data?.cluster?.id,
                        "number": response?.data?.number,
                        "name": response?.data?.name,
                        "productId": response?.data?.product?.id,
                        "description": response?.data?.description,
                        "startDate": convertDateFormatInputDate(response?.data?.startDate),
                        "lastDateCancellation": convertDateFormatInputDate(response?.data?.lastDateCancellation),
                        "maximumParticipants": response?.data.maximumParticipants,
                        "isOnline": response?.data?.isOnline,
                        "place": response?.data?.place,
                        "latitude": response?.data.latitude,
                        "longitude": response?.data.longitude,
                        "distanceSignature": response?.data?.distanceSignature,
                        "statusQualificationId": response?.data?.statusQualification.id,
                        "instructorId": response?.data?.instructor?.id,
                        "titleCertificateId": response?.data?.titleCertificate?.id,
                        "days": response?.data?.days,
                        "hours": response?.data?.hours,
                        "manualSignatureWindow": response?.data?.manualSignatureWindow,
                        "isLimit": response?.data?.isLimit,
                        // qulImage: '',
                        "urlimage":`https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.linkImage}`
        
        
                    })
                    // setFile()
    
                })
           
    }, [])

    

    return (
        <div className=' fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center py-4 w-full min-h-screen drop-shadow-2xl shadow-2xl bg-slate-600/80 overflow-y-auto'>
        <div className='bg-white w-[60%] rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
            <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                <button className='p-1 px-2 m-3 border bg-blue-700 hover:bg-blue-500' onClick={() => {
                    handleColse()
                }} >x</button>
            </div>
            


                <form className='w-full p-5 flex flex-col  text-center text-sm'>
                    <div className='w-full pt-2 flex justify-end'>

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            options={datafiler?.resClusterQS?.map((item) => { return { value: item?.id, label: item?.display } })}
                            isClearable
                            isSearchable
                            maxMenuHeight={100}
                            id="clusterId"
                            name="clusterId"
                            // value={formData?.clusterId }
                            value={
                                datafiler?.resClusterQS?.find((item) => item?.id == formData?.clusterId)
                                  ? { 
                                      value: formData.clusterId, 
                                      label: datafiler.resClusterQS.find((item) => item.id == formData.clusterId)?.display 
                                    }
                                  : null
                              }

                            onChange={(e) => handleChange(e, 'clusterId')}

                        />
                        <label htmlFor="clusterId " className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>אשכול</p></label>

                    </div>
                    {errors.fullname && <span className="error">{errors.fullname}</span>}

                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className='border w-1/2 text-right'
                            type="number"
                            id="number"
                            name="number"
                            value={formData.number}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="number" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מספר יום הכשירות</p></label>

                    </div>
                    {errors.identity && <span className="error">{errors.identity}</span>}

                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="name" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שם יום הכשורות</p></label>

                    </div>
                    {errors.phone && <span className="error">{errors.phone}</span>}


                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            id="productId"
                            name="productId"
                            options={datafiler?.resProductQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            onChange={(e) => handleChange(e, 'productId')}
                            maxMenuHeight={100}
                             value={
                                datafiler?.resProductQS?.find((item) => item?.id == formData?.productId)
                                  ? { 
                                      value: formData.productId, 
                                      label: datafiler.resProductQS.find((item) => item.id == formData.productId)?.desc 
                                    }
                                  : null
                              }
                        />
                        <label htmlFor="productId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שיוך למוצר</p></label>

                    </div>



                    <div className="w-full pt-2 flex justify-end">

                        <textarea
                            className='border w-1/2 h-20 text-right'
                            type="text"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="description" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תיאור</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="Date"
                            id="startDate"
                            name="startDate"
                            onChange={handleChangeData}
                            value={formData?.startDate}

                        />
                        <label htmlFor="startDate" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תאריך התחלה</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="Date"
                            id="lastDateCancellation"
                            name="lastDateCancellation"
                            onChange={handleChangeData}
                            value={formData.lastDateCancellation}
                        />
                        <label htmlFor="lastDateCancellation" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תאריך אחרון לביטול</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="maximumParticipants"
                            name="maximumParticipants"
                            value={formData?.maximumParticipants}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="maximumParticipants" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מספר משתתפים מקסימלי</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border  bg-slate-300'
                            type="checkbox"
                            id="isOnline"
                            name="isOnline"
                            checked={formData?.isOnline}
                            onChange={checkHandler}
                        // onChange={handleChangetext}

                        />
                        <label htmlFor="isOnline" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>האם הדרכה מקוונת</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2  bg-slate-300'

                            id="place"
                            name="place"
                            value={formData.place}
                            onChange={handleChangetext}

                        />
                        <label htmlFor="place" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מיקום</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="latitude"
                            name="latitude"
                            value={formData.latitude}
                            onChange={handleChangetext}

                        />
                        <label htmlFor="latitude" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>Latitude</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="longitude"
                            name="longitude"
                            onChange={handleChangetext}
                            value={formData.longitude}

                        />
                        <label htmlFor="longitude" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>Longitude</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="distanceSignature"
                            name="distanceSignature"
                            onChange={handleChangetext}
                            value={formData?.distanceSignature}
                        />
                        <label htmlFor="distanceSignature" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מרחק מותר לחתימה</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            type="text"
                            id="statusQualificationId"
                            name="statusQualificationId"
                            onChange={(e) => handleChange(e, 'statusQualificationId')}
                            maxMenuHeight={100}
                            options={datafiler?.resStatusQualificationQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            value={
                                datafiler?.resStatusQualificationQS?.find((item) => item?.id == formData?.statusQualificationId)
                                  ? { 
                                      value: formData.statusQualificationId, 
                                      label: datafiler.resStatusQualificationQS.find((item) => item.id == formData.statusQualificationId)?.desc 
                                    }
                                  : null
                              }
                        />
                        <label htmlFor="statusQualificationId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>סטטוס יום הכשירות</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            id="instructorId"
                            name="instructorId"
                            options={datafiler?.resInstructorQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            onChange={(e) => handleChange(e, 'instructorId')}
                            maxMenuHeight={100}
                            value={
                                datafiler?.resInstructorQS?.find((item) => item?.id == formData?.instructorId)
                                  ? { 
                                      value: formData.instructorId, 
                                      label: datafiler.resInstructorQS.find((item) => item.id == formData.instructorId)?.desc 
                                    }
                                  : null
                              }
                        />
                        <label htmlFor="instructorId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>פרטי המדריך</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            id="titleCertificateId"
                            name="titleCertificateId"
                            options={datafiler?.resCertificateQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            onChange={(e) => handleChange(e, 'titleCertificateId')}
                            maxMenuHeight={100}
                            value={
                                datafiler?.resCertificateQS?.find((item) => item?.id == formData?.titleCertificateId)
                                  ? { 
                                      value: formData.titleCertificateId, 
                                      label: datafiler.resCertificateQS.find((item) => item.id == formData.titleCertificateId)?.desc 
                                    }
                                  : null
                              }
                        />
                        <label htmlFor="titleCertificateId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>כותרת התעודה</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="days"
                            name="days"
                            onChange={handleChangetext}
                            value={formData?.days}

                        />
                        <label htmlFor="days" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>סה"כ ימים</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="hours"
                            name="hours"
                            onChange={handleChangetext}
                            value={formData?.hours}

                        />
                        <label htmlFor="hours" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>סה"כ שעות</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className=''
                            type="checkbox"
                            id="manualSignatureWindow"
                            name="manualSignatureWindow"
                        // onChange={handleChangetext}

                        />
                        <label htmlFor="manualSignatureWindow" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>השאר ריק (לשימוש עבור חריגות במהלך יום הכשירות)</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 h-fit '
                            type="file"
                            accept='image/png,image/jpeg"'
                            id="urlimage"
                            name="urlimage"
                            // value={formData?.urlimage}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="urlimage" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תמונה של היום כשירות </p></label>

                    </div>
                    {<img className='w-[200px] h-[200px]  m-4 mb-0 flex self-end' src={formData?.urlimage || ''} // ודא שאין שגיאה אם `urlimage` לא קיים
  alt="Preview"  />}


                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {

                        row ? handleEditClass(row?.id) : handleSubmit()

                        // handleSubmit()


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>
        
        </div>)
}

export default ModalNewTrainingDays