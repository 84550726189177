import './App.css';
import { useMemo } from "react";
import Login from './pages/Login';
import Main from './pages/Main'
import Members from './pages/Members'
import Tranings from './pages/Tranings'
import {
  createBrowserRouter,
  RouterProvider,useNavigate
} from "react-router-dom";
import Adminstration from './pages/Adminstration';
import HomePage from './pages/HomePage';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import RegistrationTrainingDays from './pages/RegistrationTrainingDays';
import { useEffect, useState } from 'react';
import ProtectedRoute from './Security/ProtectedRoute';
import { useAuth } from './Context/AuthProvider';
import Home from './App/Pages/Home';
import Signatures from './App/Pages/Signatures';
import Mobile from './App/Pages/Mobile';
import { redirect } from 'react-router-dom';
import MyCourses from './App/Pages/MyCourses';
import { desktopLoader, desktopregistrationTrainingDaysLoader, mobileLoader, mobileregistrationTrainingDaysLoader } from './util/mobileLoader';
function App() {

  const { auth, logout, setAuth } = useAuth()
  // const [hide,setHide]=useState("")



  // const NavigateBasedOnDevice = () => {
  //   const userAgent = navigator.userAgent;
  //   if (/iPhone|iPad|iPod|Mobi|Android/i.test(userAgent)) {
  //     console.log("sd")
  //     return redirect('/Mobile');
  //   }
  //   console.log("sds")
  //   return redirect('/');
  // };



  // const NavigateBasedOnDevice = () => {
  //   const navigate = useNavigate();  // useNavigate hook is called here inside a component wrapped by Router
  //   // useEffect(() => {
  //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //     if (isMobile) {
  //       console.log("moblie")
  //        navigate('/Mobile');
  //     } else {
  //       console.log("cp")
  //       navigate('/');
        
  //     }
  //   // }, [navigate]);
  // }




  const router = useMemo(() => createBrowserRouter([
      {
        path: "",
        element: <Main />,
        errorElement: <ErrorPage/>,
        children: [
          { path: "", loader:desktopLoader, element: <ProtectedRoute isAuthenticated={auth}><HomePage /></ProtectedRoute> },
          { path: "members",loader:desktopLoader, element: <ProtectedRoute isAuthenticated={auth}><Members /></ProtectedRoute> },
          { path: "tranings",loader:desktopLoader, element: <ProtectedRoute isAuthenticated={auth}><Tranings /></ProtectedRoute> },
          { path: "adminstration",loader:desktopLoader, element: <ProtectedRoute isAuthenticated={auth}><Adminstration /></ProtectedRoute> },
          { path: "registrationTrainingDays/:corseId" ,loader:desktopregistrationTrainingDaysLoader, element: <ProtectedRoute isAuthenticated={auth}><RegistrationTrainingDays/></ProtectedRoute> },
          { path: "registrationTrainingDays" ,loader:desktopLoader, element: <ProtectedRoute isAuthenticated={auth}><RegistrationTrainingDays/></ProtectedRoute> }
        ]
      },
      { path: "/login", element: <Login/> },
      { path: "/Signup", element: <Signup/> },
      { 
        path: "/Mobile",
        // loader: mobileLoader,// הוספת ה-loader כאן
        element: <Mobile/>,
        children: [
          { path: "" , loader: mobileLoader, element: <ProtectedRoute isAuthenticated={auth}><Home /></ProtectedRoute> },
          { path: ":corseId", loader:mobileregistrationTrainingDaysLoader, element: <ProtectedRoute isAuthenticated={auth}><Home /></ProtectedRoute> },
          { path: "Signatures",loader: mobileLoader, element: <ProtectedRoute isAuthenticated={auth}><Signatures /></ProtectedRoute> },
          { path: "MyCourses", loader: mobileLoader,element: <ProtectedRoute isAuthenticated={auth}><MyCourses/></ProtectedRoute> }
        ]
      }
  ]), [auth]);
  
   
 


  return (
    // <div className={`App font-sans text-xs relative h-screen  ${hide!=""?"overflow-hidden":""}`}>
    <div className={`App font-sans text-xs relative h-screen`}>
     
      <RouterProvider router={router} >
      </RouterProvider>
    </div>
  );
}

export default App;
// div {
//   margin-bottom: 10px;
//   position: relative;
// }

// input[type="number"] {
//   width: 100px;
// }

// input + span {
//   padding-right: 30px;
// }

// input:invalid + span::after {
//   position: absolute;
//   content: "✖";
//   padding-left: 5px;
// }

// input:valid + span::after {
//   position: absolute;
//   content: "✓";
//   padding-left: 5px;
// }
