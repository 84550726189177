import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReDataTable from '../../ui/ReDataTable';

import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";

import { HeaderTable } from '../../HeaderTable';
const UserMemberOnApp = ({row}) => {
    const GET_URL=`admin/member/get_user_member_on_app/${row?.id}`
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);

    // const handleColse = () => {
    //     setModalIsOpen("")
    // }
    
    const getData = async (page,perPage) => {
        const response= await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            if (response.status=="200") 
             {

                const response1 = [(response.data)]
                const response2= (response?.data?.totalElements);
                return [response1, response2];
            }
            const response1 = []
            const response2= []
            return [response1, response2];
      };
      const getDataTable = async (page,perPage) => {


        
        const [result1, result2] = await getData(page,perPage)
        setFetchData(result1);
        setTotalRows(result2); 
        setloading(false)
        console.log(fetchData)
      };
    const columns = [
        // {
        //   name: "פעולה",
    
        //   cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
        //     setId(row)
        //     setModalIsOpen("Edit")
        //   }
        //   }><BsPencil /></button>
        //     <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
        //       setId(row)
        //       setModalIsOpen("Delete")
        //     }}><IoTrashOutline /></button> </div>,
        //   //handleDeteleClass(row?.id)
        //   style: {
        //     fontSize: '16px',
        //     justifyContent: 'center',
    
        //   },
        //   grow: 2,
    
    
        // },
        // {
        //     name: "פעולות",
        //     grow: 2,
        //     cell: row => <div className='w-full'>
                
        //         <button title="מחיקה" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
        //             setId(row)
        //             setModalIsOpen("Delete")
        //         }}><IoTrashOutline /></button>
        //         <button className='  p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
        //         // setnamecalss(row?.description)
        //         // setAction("edit")
        //         setId(row)
        //         setModalIsOpen("Edit")
        //     }
        //     }><BsPencil /></button>
        //     </div>,
        //     style: {
        //       fontSize: '16px',
        //       justifyContent: 'center',
        //       width: '100px'
        //     },
           
        //   }
      //  ,
       
        {
          name: "מספר חבר",
          grow: 2,
          selector: (row) => row?.number,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
          
        },
        {
            name: "טלפון",
            grow: 2,
            selector: (row) => row?.mainPhone,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            
          },
        {
            name: "דוא\"ל",
            grow: 2,
            selector: (row) => row?.email,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
           
          },
        {
            name: "סיסמא",
            grow: 2,
            selector: (row) => row?.number,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
          
          },
        {
            name: "שם משתמש",
            grow: 2,
            selector: (row) => row?.mainPhone,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
           
          },
        {
            name: "שם מלא",
            grow: 2,
            selector: (row) => `${row?.firstName} ${row.lastName}`,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
           
          }
       
    
    
    
      ];

  return (
    <div style={{direction:"ltr"}} >
            <HeaderTable active={"משתמש לאפליקציה"}/>
             <ReDataTable columns={columns} getDataTable={getDataTable} fetchData={fetchData} loading={loading} totalRows={totalRows}/>
             {/* {modalIsOpen=="New"&&<NewActivityday handleColse={handleColse}>

             </NewActivityday>} */}
        </div>
  )
}

export default UserMemberOnApp