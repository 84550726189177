import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import v from '../assets/v.svg'
import x from '../assets/x.svg'
import Header from '../components/Header'
import axios from 'axios'
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { validateIsraeliID } from '../util/validation'

const Signup = () => {


    // const arrtel = ["קידומת", "050", "051", "052", "053", "054", "055", "057", "058", "059"]
    // const arrJobsPlace = ["עצמאי", "שכיר במוסד ממשלתי", "שכיר ברשות מקומית", "שכיר צה\"ל", "שכיר בחברה לייעוץ בטיחות", "שכיר בקיבוץ/מקום עבודה שיתופי", "שכיר במקום עבודה בפרטי", "אחר"]


    const GET_CREACT = 'auth/register_member'
    const arr = ["בחר", "עצמאי", "שכיר במוסד ממשלתי", "שכיר בצה\"ל", "שכיר בחברה לייעוץ בטיחות", "שכיר בקיבוץ/מקום עבודה שיתופי", "שכיר במקום עבודה פרטי", "אחר"]
    const navigate = useNavigate();
    const arrtel = ["050", "051", "052", "053", "054", "055", "057", "058", "059"]
    const [citysList, setcitysList] = useState([]);
    const [streetList, setstreetList] = useState([]);
    const [errors, setErrors] = useState({})
    // const [dataFiles, setDataFiles] = useState({
    //     img_face_picture: null,
    //     img_certificate: null,
    //     img_signature: null,
    // })
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        id: '',
        birthDate: '',
        workplace: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        city: '',
        email: '',
        phonePrefix: '',
        phone: '',
        extraPhonePrefix: '',
        extraPhone: '',
        qualificationNumber: '',
        img_face_picture: null,
        img_certificate: null,
        signature: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const sign = useRef()
    const [validID, setValidID] = useState(0)
    const [validTal, setValidTal] = useState(false)

    function clearSign() {
        sign.current.clear()
        // console.log(sign)
    }
    function checklen(number) {
        let strNumber = String(number).trim();
        if (strNumber.length != 7) {
            setValidTal(true)
            return
        }
        setValidTal(false)
    }
    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case "firstName":
                if (!value.trim()) {
                    error = "שם לקוח הינו שדה חובה.";
                }
                break;
            case "lastName":
                if (!value.trim()) {
                    error = "הינו שדה חובה."
                }

                break;
            case "qualificationNumber":
                if (!value.trim()) {
                    error = "הינו שדה חובה."
                }

                break;
            case "id":
                if (!value.trim()) {
                    error = " הינו שדה חובה.";
                }
                if (value.trim() && !/^\d+$/.test(value)) {
                    error = "מספר טלפון חייב להיות מספרים בלבד.";
                }
                // else
                //     if (value.trim() && !validateIsraeliID(value)) {
                //         error = " ת.ז שגוי";
                //     }
                break;
            case "dateRegister":
                if (!value.trim()) {
                    error = " הינו שדה חובה.";
                }

                break;



            default:
                break;
        }

        return error;
    };
    const validate = () => {
        const newErrors = {};

        if (!formData?.firstName?.trim()) {
            newErrors.firstName = "הינו שדה חובה.";
        }
        if (!formData?.lastName?.trim()) {
            newErrors.lastName = "הינו שדה חובה."
        }
        if (!formData?.img_certificate) {
            newErrors.img_certificate = "הינו שדה חובה."
        }
        if (!formData?.img_certificate) {
            newErrors.img_certificate = "הינו שדה חובה."
        }
        if (!formData?.phone ) {
            newErrors.phone = "הינו שדה חובה."
         }
         if (!formData?.phonePrefix ) {
            newErrors.phone = "מספר לא תקין."
         }
         if (!formData?.id) {
            newErrors.id = "הינו שדה חובה."
         }
         if (formData?.id && !validateIsraeliID(formData?.id) ) {
            newErrors.id = "תעודת זהות לא תקינה."
         }
        if (formData?.phonePrefix && !/^\d{7}$/.test(formData.phone)) {
           newErrors.phone = "מספר לא תקין."
        }
       
       



         if (formData?.extraPhonePrefix && !/^\d{7}$/.test(formData.extraPhone) ) {
            newErrors.extraPhone = "מספר לא תקין."
         }
       

        if (!formData?.img_face_picture) {
            newErrors.img_face_picture = "הינו שדה חובה."
        }
        
        if (!formData?.workplace?.trim()) {
            newErrors.workplace = "הינו שדה חובה."
        }
        if (!formData?.birthDate?.trim()) {
            newErrors.birthDate = "הינו שדה חובה."
        }
        if (!formData?.id?.trim()) {
            newErrors.id = "הינו שדה חובה.";
        }
        if (!formData?.qualificationNumber?.trim()) {
            newErrors.qualificationNumber = "הינו שדה חובה.";}
        
            if (sign.current.isEmpty()) {
            newErrors.img_signature = "הינו שדה חובה.";
        }
            if (sign.current) {
                console.log(sign.current.toDataURL())
        }
        
      
        if (!formData?.email?.trim()) {
            newErrors.email = "הינו שדה חובה.";
        }


        if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'אימייל לא תקין';
          
        }





     
    
        if (!formData?.street?.trim()) {
            newErrors.street = "הינו שדה חובה.";
        }
        if (!formData?.city?.trim()) {
            newErrors.city = "הינו שדה חובה.";
        }



        setErrors(newErrors);
        console.log(newErrors)
        return Object.keys(newErrors).length === 0;
    };

    function isIsraeliIdValid(id) {
        let strId = String(id).trim();
        if (strId.length == 0) {
            setValidID(0)
            return
        }
        if (strId.length > 9) {
            // return false;
            setValidID(2);
        }
        if (strId.length < 9) {
            while (strId.length < 9) strId = "0" + strId;
        }
        let counter = 0, rawVal, actualVal;
        for (let i = 0; i < strId.length; i++) {
            rawVal = Number(strId[i]) * ((i % 2) + 1);
            actualVal = rawVal > 9 ? (rawVal - 9) : rawVal;
            counter += actualVal;
        }
        // return (counter % 10 === 0);
        setValidID(counter % 10 === 0 ? 1 : 2);
    };
    function dataURLtoBlob(dataURL) {
        const arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while(n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
    const addNewMember = async () => {

        const formData1 = new FormData();
        formData1.append('memberDetails', JSON.stringify(
            {
                "dateRegister": "",
                "number": "",
                "firstName": formData?.firstName,
                "lastName": formData?.lastName,
                "idNumber": formData?.id,
                "dateBorn": formData?.birthDate,
                "job": formData?.workplace,
                "street": formData?.street,
                "apartmentNumber": Number(formData?.apartmentNumber),
                "houseNumber": Number(formData?.houseNumber),
                "city": formData?.city,
                "email": formData?.email,
                "mainPhone": `${formData?.phonePrefix}${formData?.phone}`,
                "additionalPhone": formData?.additionalPhone,
                "nsNumber": formData?.qualificationNumber,
                "status": "",
                // "status": formData?.status,
                "payingCustomerId": ""
                // "payingCustomerId": Number(formData?.payingCustomerId)
            }
        ));
        formData1.append('img_face_picture', formData.img_face_picture);
        formData1.append('img_certificate', formData.img_certificate);
        // formData1.append('img_signature', formData.img_signature);
        formData1.append('img_signature', dataURLtoBlob(sign.current.toDataURL()),"signature.png");

       
        const response = await axios.post(GET_CREACT, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })


        console.log(response)
        if (response?.status == 201) {
            // setnamecalss("")
            //צריך להוסיף מעבר להתחברות
            alert("הטופס נשלח בהצלחה!\nפרטי המשתמש והסיסמה נשלחו אליך לאימייל.");
            navigate("/login");


        }
        else {
            // console.log(response)
            alert(response?.response?.data.message)
        }
    }
    const handleSubmit = (e) => {
        // addNewMember()
        e.preventDefault();
        if (validate()) {
            addNewMember()
            // console.log('טופס נשלח בהצלחה:', formData);
            // כאן אפשר לשלוח את הנתונים לשרת או לבצע פעולות נוספות
        }
        else { alert("אחד מהשדות חובה ריקים או אינם תקינים") }
    }

    const getCity = async () => {
        // await axios.get('https://data.gov.il/api/3/action/datastore_search', {

        //         params: { resource_id:"5c78e9fa-c2e2-4771-93ff-7f400a12f7ba",q:"", limit:"100" },
        //         responseType: "json",
        //       }).then(response => {
        //     if (response.status == 200) {
        //         console.log("response?.data")
        //     }
        // })
        await axios.get('auth/all_city', {
        }).then(response => {
            if (response.status == 200) {
                setcitysList(response?.data)
            }
        })
    }
    const getStreets = async () => {

        if (formData.city) {
            await axios.get(`auth/all_street/${formData.city}`, {
            }).then(response => {
                if (response.status == 200) {
                    setstreetList(response?.data)
                }
            })
        }
        else { setstreetList([]) }
    }
    useEffect(() => {
        getCity()
    }, [])
    useEffect(() => {
        getStreets()
        formData.street = ""
    }, [formData.city])

    useEffect(() => {
        console.log(formData)
    }, [formData])
    return (
        <div className='bg-[#f3f3f3] p-4 pb-6 min-h-screen'>
            <Header />
            <form onSubmit={handleSubmit} className='flex flex-col items-center border rounded-md bg-white ' style={{ direction: "rtl" }}>
                <text className='font-black underline text-5xl'> טופס הרשמה ללשכת ממוני הבטיחות בישראל (ע.ר.)</text>
                {/* <fieldset class="border border-solid border-gray-300 p-3  flex flex-col items-end "> */}
                <fieldset class="border border-solid border-gray-300 p-3  bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2 ">

                    <legend align="right" className='bg-black text-white p-3 rounded-md'>פרטים אישיים</legend>
                    <label htmlFor='fristname' className='text-right'>
                        שם פרטי*
                    </label>
                    <input name="firstName" value={formData?.firstName} className='h-7' onChange={handleChange} />
                    < div className="text-red-500 text-center">{errors?.firstName && <p>{errors.firstName}</p>}</div>

                    <label htmlFor='lastName' className='text-right'>שם משפחה*</label>
                    <input name="lastName" className='h-7' value={formData?.lastName} onChange={handleChange} />
                    < div className="text-red-500 text-center">{errors?.lastName && <p>{errors.lastName}</p>}</div>



                    <label htmlFor='id' className='text-right'>ת.ז*</label>

                    <div className='flex'>
                        <input type='number' name='id' value={formData?.id} className='h-7' onChange={handleChange} onBlur={(event) => { isIsraeliIdValid(event.target.value) }} />
                        {/* {validID == 1 && <img src={v} alt="v.svg" />}
                        {validID == 2 && <img src={x} alt="x.svg" />} */}

                    </div>
                    {errors.id && < div className="text-red-500 text-center flex">  <img src={x} alt="x.svg" /> {errors.id}</div>}
                    
                    <label htmlFor='birthDate' className='text-right'>תאריך לידה*</label>
                    <input className='h-7' type='date' name='birthDate' value={formData?.birthDate} onChange={handleChange} />
                    {errors?.birthDate && < div className="text-red-500 text-center"> {errors.birthDate}</div>}



                    <label htmlFor='workplace' className='text-right'>מקום עבודה*</label>
                    <select className='h-7 mt-2' name='workplace' onChange={handleChange}>
                        {
                            arr.map((index) => {
                                return (<option className='w-full'>
                                    {index}
                                </option>)
                            })
                        }
                    </select>
                    {errors?.workplace && < div className="text-red-500 text-center"> {errors.workplace}</div>}

                </fieldset>


                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2">

                    <legend align="right" className='bg-black text-white p-3 rounded-md'>כתובת</legend>
                    <label htmlFor='city' className='text-right'>עיר</label>
                    {/* <input name="city" className='h-7' value={formData?.city} onChange={handleChange} /> */}
                    {/* <Select className='  grid col-span-5 p-0 text-right'
                                options={citysList?.map((item) => { return { value: item, label: item } })}
                                placeholder="חפש..."
                                isClearable
                                isSearchable
                                // styles={customStyles}
                                maxMenuHeight={100}
                                // name="city"
                                value={formData?.city}
                                // onChange={(e) => handleChangeSelect(e, 'city')}
                            ></Select>
                             */}
                    <select className='h-7 mt-2' value={formData?.city} name='city' onChange={handleChange}>
                    <option value="" disabled hidden>בחר</option>
                        {
                            
                            citysList.map((index) => {
                                return (<option className='w-full'>
                                    {index}
                                </option>)
                            })
                        }
                    </select>
                    {errors?.city && < div className="text-red-500 text-center"> {errors.city}</div>}

                    <label htmlFor='street' className='text-right'>רחוב</label>

                    <select className='h-7 mt-2' value={formData?.street} name='street' disabled={!formData?.city} onChange={handleChange}>
                    <option value="" disabled hidden>בחר</option>
                        {
                            streetList.map((index) => {
                                return (<option className='w-full'>
                                    {index}
                                </option>)
                            })
                        }       </select>
             {errors?.street && < div className="text-red-500 text-center"> {errors.street}</div>}
                    <label htmlFor='houseNumber' className='text-right'>מספר בית</label>
                    <input name="houseNumber" className='h-7' value={formData?.houseNumber} onChange={handleChange} />


                    <label htmlFor='apartmentNumber' className='text-right'>מספר דירה</label>
                    <input name="apartmentNumber" className='h-7' value={formData?.apartmentNumber} onChange={handleChange} />



                </fieldset>

                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2 text-right"  >

                    <legend align="right" className='bg-black text-white p-3 rounded-md'>פרטי תקשורת</legend>

                    <label htmlFor='email' className='text-right'>דוא"ל*</label>
                    <input type="email" name="email" className='h-7' value={formData?.email} onChange={handleChange} />
                    {errors?.email && < div className="text-red-500 text-center"> {errors.email}</div>}



                    <label htmlFor='phone' className='text-right' >טלפון ראשי*</label>
                    <div className=' grid grid-cols-4 gap-4'>

                        <div className=' grid col-span-2'>
                            <input type='number' name='phone' className='col-span-2 h-7' onChange={handleChange} 
                            // onBlur={(event) => { checklen(event.target.value) }}
                             />
                            {/* {validTal && <img src={x} alt="x.svg" />} */}
                        </div>
                        <select className='h-7 ml-15' name='phonePrefix' onChange={handleChange}>
                        <option value="" >בחר</option>
                            {
                                arrtel.map((index) => {
                                    return (<option className='w-full'>
                                        {index}
                                    </option>)
                                })
                            }
                        </select>

                    </div>
                    {errors?.phone && < div className="text-red-500 text-center flex">  <img src={x} alt="x.svg" /> {errors?.phone}</div>}
                    
                    
                    <label htmlFor='extraPhonePrefix' className='text-right'>טלפון נוסף (אופציונלי)</label>
                    <div className=' grid grid-cols-4 gap-4'>

                        <div className=' grid col-span-2'>
                            <input type='number' name='extraPhone' className='col-span-2 h-7' onChange={handleChange} onBlur={(event) => { checklen(event.target.value) }} />
                            {/* {false&&<img src={x} alt="x.svg" />} */}
                        </div>
                        <select className='h-7 ml-15' name='extraPhonePrefix' onChange={handleChange}>
                        <option value="" >בחר</option>
                            {
                                arrtel.map((index) => {
                                    return (<option className='w-full'>
                                        {index}
                                    </option>)
                                })
                            }
                        </select>
                    </div>
                        {errors?.extraPhone && < div className="text-red-500 text-center flex">  <img src={x} alt="x.svg" /> {errors?.phone}</div>}
                </fieldset>



                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2" >

                    <legend align="right" className='bg-black text-white p-3 rounded-md'>אישור כשירות</legend>
                    <label htmlFor='qualificationNumber' className='text-right'>מספר נ.ש.* </label>
                    <input type='number' name="qualificationNumber" className='border border-solid border-spacing-1 w-[80%] h-7' onChange={handleChange} />
                    <a href="https://serviceproviders.labor.gov.il/Pages/Person.aspx" target="_blank" className='text-right w-fit bg-slate-500 m-1 p-2 rounded-lg text-white'> מעבר לאתר משרד העבודה</a>
                    {errors?.qualificationNumber && < div className="text-red-500 text-center"> {errors.qualificationNumber}</div>}

                </fieldset>




                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2" >

                    <legend align="right" className='bg-black text-white p-3 rounded-md'>  קבצים מצורפים</legend>
                    <label htmlFor='img_face_picture' className='text-right'>תמונת פנים (התמונה תשמש לכרטיס חבר)</label>
                    <input type="file"
                        name="img_face_picture"
                        onChange={(e) =>
                            setFormData({ ...formData, img_face_picture: e.target.files[0] })
                        } className='border border-solid border-spacing-1 w-[50%]' />
                       {errors?.img_face_picture && < div className="text-red-500 text-center"> {errors.img_face_picture}</div>} 

                    <label htmlFor='img_certificate' className='text-right'>אישור כשירות</label>
                    <input type='file' name='img_certificate'
                        onChange={(e) =>
                            setFormData({ ...formData, img_certificate: e.target.files[0] })
                        } className='border border-solid border-spacing-1 w-[50%]' />
                        {errors?.img_certificate && < div className="text-red-500 text-center"> {errors.img_certificate}</div>}
                </fieldset>





                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2 text-right" >

                    <legend align="right" className='bg-black text-white p-3 rounded-md'>הצהרה</legend>
                    <p className=' whitespace-pre-line'> אני הח"מ, מבקש להיות חבר בעמותה – לשכת ממוני הבטיחות בישראל מקצונים זה אנחנו (ע.ר.) 580700185.
                        מטרות העמותה כפי שרשומות להלן ותקנונה ידועים לי.
                        אם אתקבל כחבר אני מתחייב לקיים את הוראות התקנון ואת החלטות האסיפה הכללית של העמותה.
                        הנני מאשר / ת שליחת הודעות דוא"ל מעת לעת הכוללות אישורים, הנחיות והודעות אחרות לכתובת הדוא"ל / למספר הסלולרי שסיפקתי.
                        מטרות העמותה
                        1. הקמת ארגון יציג לפי כל דין לכלל ממוני הבטיחות בישראל.
                        2. לפעול למען קידומם המקצועי של ממוני הבטיחות בין היתר לפי המלצת "וועדת אדם".
                        3. לפעול למען רווחתם של ממוני הבטיחות בין היתר על ידי הפחתת כמות ימי העיון הנדרשת ממונה הבטיחות לפי דין ו/או הגבלת התשלום בגין ביצוע ימי העיון באופן שלא יעלה על 100 ₪ לכל יום עיון.
                        תקנון העמותה
                        התקנון המצוי באתר העמותה.
                    </p>
                    <label>חתימה:</label>
                    <SignatureCanvas backgroundColor='white' canvasProps={{ className: 'w-[200px] h-[200px] m-2' }} ref={sign}></SignatureCanvas>
                    {errors?.img_signature && < div className="text-red-500 text-center"> {errors.img_signature}</div>}
                    <button type='button' className='p-4 w-fit' onClick={clearSign}>נקנה חתימה</button>
                </fieldset>

                <button className='p-4 bg-lime-500 mb-4 mt-2' >שלחת טופס</button>

            </form>

        </div>
    )
}

export default Signup