import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { formatDateTime } from "../../../util/formatsForDate";

const NewAddItemForPurchase_archive = ({ handleColse, row, getDataTable }) => {
    const [formData, setFormData] = useState({
        date: "",
        time: "",
        product: null,
        quantity: "",
        totalPrice: 0,
        invoiceNumber: "",
        receiptNumber: "",
        notes: "",
    });
    // const [formData, setFormData] = useState({
    //     date: row?.date || "",
    //     time: row?.time || "",
    //     product: row?.productId || null,
    //     quantity: row?.quantity || "",
    //     totalPrice: row?.totalPrice || 0,
    //     invoiceNumber: row?.invoiceNumber || "",
    //     receiptNumber: row?.transactionId || "",
    //     notes: row?.note || "",
    //   });
    const [products, setProducts] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        axios
            .get("admin/setting/product") // קריאה לשרת לקבלת רשימת מוצרים
            .then((response) => {
                setProducts(response?.data);
            })
            .catch((err) => console.error("Error fetching products:", err));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleProductChange = (selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            product: selectedOption,
            totalPrice: selectedOption ? selectedOption?.price * formData?.quantity : 0,
        }));
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value.replace(/\D/g, ""); // רק מספרים
        setFormData((prev) => ({
            ...prev,
            quantity: value,
            totalPrice: prev.product ? prev.product.price * value : 0,
        }));
    };

    const handleSubmit = (e) => {
        // e.preventDefault();
        if (!formData.date || !formData.time || !formData.product || !formData.quantity) {
            setError("יש למלא את כל השדות החובה");
            return;
        }
        setError("");
        addNewPurchase(formData);
    };


    const addNewPurchase = async () => {
        const response = await axios.post(`admin/setting/request_buy_product_for_paying_id_without_paying/${row?.id}`, JSON.stringify({
            "dateOpen": formatDateTime(formData?.date , formData?.time),
            "productId": formData?.product?.value,
            "quantity": formData?.quantity,
            "transactionId": formData?.receiptNumber,
            "invoice": formData?.invoiceNumber,
            "note": formData?.notes
                   }), {
            headers: { 'Content-Type': 'application/json' }
        })
        // console.log({  "dateOpen": formatDateTime(formData?.date , formData?.time),
        //     "productId": formData?.product?.value,
        //     "quantity": formData?.quantity,
        //     "transactionId": formData?.receiptNumber,
        //     "invoice": formData?.invoiceNumber,
        //     "note": formData?.notes,})
        if (response?.status == 200) {
            // setnamecalss("")
            alert("הטופס נשלח בהצלחה!");
            await getDataTable()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }
    return (
        <div className='fixed  top-0 h-screen  overflow-y-auto left-0 flex flex-col items-center pt-2 w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50 '
            onClick={() => {
                // handleColse()
            }}>
            <div className='bg-white w-2/5 rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                <div className="w-full  flex flex-col items-center ">
                <form className='max-md:w-[500px] w-full  m-[30px auto]  flex flex-col  ' style={{ direction: "rtl" }} >
                <div className="flex w-full items-center p-2 gap-2">
                    <label className="w-1/4 text-center">תאריך *</label>
                    <input type="date" name="date" value={formData.date} onChange={handleChange} required className=" w-32 p-2 border" />
                    </div>
                    <div className="flex w-full items-center p-2 gap-2">
                    <label className="w-1/4 text-center">שעה *</label>
                    <input type="time" name="time" value={formData.time} onChange={handleChange} required className="w-32 p-2 border" />
                    </div>
                    
                    
                    <div className="flex items-center p-2 gap-2">
                    <label className="w-1/4 text-center">מוצר *</label>
                    <Select className="w-2/3 border rounded " options={products?.map((p) => ({ value: p.id, label: p.name, price: p.price }))} onChange={handleProductChange} placeholder="בחר מוצר"  />
                    </div>
                    <div className="flex w-full items-center p-2 gap-2">
                    <label className="w-1/4 text-center">כמות *</label>
                    <input className="w-2/3 border rounded p-2" type="text" name="quantity" value={formData.quantity} onChange={handleQuantityChange} required />
                    </div>

                    <div className="flex w-full items-center p-2 gap-2">
                    <label className="w-1/4 text-center">סה"כ לתשלום</label>
                    <input className="w-2/3 border rounded p-2 bg-gray-200" type="text" value={formData.totalPrice} disabled   />
                    </div>
                    
                    
                    <div className="flex w-full items-center p-2 gap-2">
                    <label className="w-1/4 text-center">מספר חשבונית</label>
                    <input className="w-2/3 border rounded p-2" type="text" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleChange}  />
                    </div>
                   
                    <div className="flex w-full items-center p-2 gap-2">
                    <label className="w-1/4 text-center">מספר קבלה</label>
                    <input className="w-2/3 border p-2 rounded" type="text" name="receiptNumber" value={formData.receiptNumber} onChange={handleChange}  />
                    </div>


                    <div className="flex items-center p-2 gap-2">
                        <label className="w-1/4 text-center">הערות</label>
                        <textarea className="w-2/3 min-h-20 p-2 border  rounded" name="notes" value={formData.notes} onChange={handleChange} ></textarea>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}





                </form >
                </div>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        // if (nameRole != "") {
                        // row ? handleEditRole(row.id) :
                        // addNewMember()
                        {
                            // row ? handleEditClass(row.id) : handleSubmit()
                        }
                        handleSubmit()
                        // }


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div >
        </div >
    )
}

export default NewAddItemForPurchase_archive