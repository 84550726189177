import React from 'react'

const Cancel = ({handleColse,handleDetele}) => {
    return (
        <div className=' fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center py-4 w-full min-h-screen drop-shadow-2xl shadow-2xl bg-slate-500/40 overflow-y-auto'>
            <div className=' w-1/2 divide-y-2  divide-slate-500 bg-white rounded-md'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={handleColse}
                    // setShowModal(false)

                    >x</button>
                </div>
                <div className='w-full p-5 flex flex-row justify-end '>
                    <p className='w-full text-center'>?האם אתה בטוח שברצונך למחוק את הרשומה</p>

                </div>

                <div className='w-full p-2   flex justify-center'>
                    <button className='border bg-blue-500 hover:bg-blue-950 p-1 px-3 rounded-lg' onClick={handleDetele}>אוקי</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={handleColse}
                    >בטל</button>
                </div>
            </div></div>
    )
}

export default Cancel