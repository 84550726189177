import React, { useEffect, useState } from 'react'
import Stepper from '../copmponents/Stepper'
import Signature from '../copmponents/Signature'
import axios from 'axios'
import {formatSignWindow } from '../../util/formatsForDate'

const Signatures = () => {
    const GET_URL="dashboard/member/windows_open_for_attendance"
    const [stepsData, setStepsData] = useState({
        qul_id: null,
        signatureWindows_id:null,
        signatureWindowsDto:null,
        indexSignatures:null,
        numberSign:0})
    const [dataCoures, setDataCoures] = useState({
       
        numberCluster:"",
        nameCluster:"",
        numberQ:"",
        nameQ:"",
        description:"",
        place:"",
        nameInsturcation:"",
        signatureDates:{}

    })
    const [location , setLocation] = useState({
        latitude: 0, longitude: 0 
    })

    const getDataSignatures = async () => {

        await axios.get(GET_URL).then(response => {
            setDataCoures({ 
                
              
                numberCluster:response?.data?.qualificationDayDto?.cluster?.number,
                nameCluster:response?.data?.qualificationDayDto?.cluster?.name,
                numberQ:response?.data?.qualificationDayDto?.number,
                nameQ:response?.data?.qualificationDayDto?.name,
                description:response?.data?.qualificationDayDto?.description,
                place:response?.data?.qualificationDayDto?.place,
                nameInsturcation:response?.data?.qualificationDayDto?.instructor?.frame_manager,
                signatureDates:response?.data?.signatureWindowsDto
            }
        )
        setStepsData({
            qul_id: response?.data?.qul_id,
            signatureWindows_id: response?.data?.signatureWindows_id,
            signatureWindowsDto:response?.data?.signatureWindowsDto,
            indexSignatures:response?.data?.indexSignatures,
            numberSign:response?.data?.numberSign
        })    
     })
        // setloading(false)
    }

    // const getLocation = () => 
    // {
    //     if(navigator.geolocation)
    //     {
    //         navigator.geolocation.getCurrentPosition(
    //             (position => setLocation({ latitude: position.coords.latitude , longitude: position.coords.longitude }) 
    //         ) , () => {} ,  { enableHighAccuracy: true }) //err
    //     }
    // }


    // const getLocation = () => {
    //     return new Promise(async (resolve, reject) => {

    //         const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
    //         if (permissionStatus.state === "denied") {
    //             //console.warn("Location permission denied. Returning (0,0).");
    //             const fallbackCoords = { latitude: 0, longitude: 0, accuracy: 0, source: "geolocation denied" };
    //             setLocation(fallbackCoords); // עדכון ה-state במקרה של fallback
    //             resolve(fallbackCoords); // מחזיר את המיקום לשימוש מיידי
    //         }
    //         else
    //         {
    //             if (navigator.geolocation) {
    //                 navigator.geolocation.getCurrentPosition(
    //                     (position) => {
    //                         const coords = {
    //                             latitude: position.coords.latitude,
    //                             longitude: position.coords.longitude,
    //                             accuracy: position.coords.accuracy, // הדיוק של המיקום
    //                             source: "geolocation" // מקור המיקום - GPS
    //                         };
    //                             // בדיקה אם המיקום הוא 0,0
    //                         if (coords.latitude === 0 && coords.longitude === 0) {
    //                             //console.warn("Received 0,0 as location, trying fallback...");
    //                             getLocationFromGoogle().then((fallbackCoords) => {
    //                                 setLocation(fallbackCoords);
    //                                 resolve(fallbackCoords);
    //                             });
    //                         } else {
    //                             setLocation(coords); 
    //                             resolve(coords); 
    //                         }
    //                     },
    //                     async (error) => {
    //                         //console.error("GPS Error:", error.message);
    //                         const fallbackCoords = await getLocationFromGoogle();
    //                         setLocation(fallbackCoords); // עדכון ה-state במקרה של fallback
    //                         resolve(fallbackCoords); // מחזיר את המיקום לשימוש מיידי
    //                     },
    //                     { enableHighAccuracy: true , timeout: 15000  , maximumAge: 60000 }
    //                 );
    //             } else {
    //                 const fallbackCoords = await getLocationFromGoogle();
    //                 setLocation(fallbackCoords); // עדכון ה-state במקרה של fallback
    //                 resolve(fallbackCoords); // מחזיר את המיקום לשימוש מיידי
    //             }
    //         }
    //     });
    // };
    
    // const getLocationFromGoogle = async () => {
    //     const API_KEY = "AIzaSyChYUm98Hn4cIkEn0BlpdrvIwIbIJID1-k";
    //     try {
    //         const response = await fetch(
    //             `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`,
    //             { method: "POST" }
    //         );
    //         const data = await response.json();
    //         if (data.location && data.accuracy) {
    //             return {
    //                 latitude: data.location.lat,
    //                 longitude: data.location.lng,
    //                 accuracy: data.accuracy, // גוגל מחזירה את הדיוק במטרים
    //                 source: "Google Geolocation API" // ציון המקור
    //             };
    //         } else {
    //             if(data.location)
    //             {
    //                 return {
    //                     latitude: data.location.lat,
    //                     longitude: data.location.lng,
    //                     accuracy: 1000, // ערך ברירת מחדל אם אין נתונים
    //                     source: "Google Geolocation API"
    //                 };
    //             }
    //             else{
    //                 return {
    //                     latitude: 0,
    //                     longitude: 0,
    //                     accuracy: 0, // ערך ברירת מחדל אם אין נתונים
    //                     source: "Google Geolocation API Failure"
    //                 };
    //             }
                
    //         }
    //     } catch (error) {
    //         //console.error("Error fetching location from Google:", error);
    //         return {
    //             latitude: 0,
    //             longitude: 0,
    //             accuracy: 0, // ערך ברירת מחדל במקרה של שגיאה
    //             source: "Google Geolocation API"
    //         }; // במקרה של שגיאה
    //     }
    // };

    // 03.04.2025
    // const getLocation = () => {
    //     return new Promise(async (resolve) => {
    //         const MAX_TRIES = 5; // מקסימום ניסיונות
    //         const MIN_ACCURACY = 300; // דיוק מקסימלי רצוי
    //         let attempts = 0;
    //         let bestLocation = null; // ישמור את המיקום הכי טוב שמצאנו
    
    //         const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
    //         if (permissionStatus.state === "denied") {
    //             const fallbackCoords = { latitude: 0, longitude: 0, accuracy: 0, source: "geolocation denied" };
    //             setLocation(fallbackCoords);
    //             return resolve(fallbackCoords);
    //         }
    
    //         const fetchGeolocation = () => {
    //             return new Promise((resolve) => {
    //                 navigator.geolocation.getCurrentPosition(
    //                     (position) => {
    //                         const coords = {
    //                             latitude: position.coords.latitude,
    //                             longitude: position.coords.longitude,
    //                             accuracy: position.coords.accuracy,
    //                             source: "geolocation"
    //                         };
    //                         resolve(coords);
    //                     },
    //                     () => resolve(null), // במקרה של כשלון, מחזירים null
    //                     { enableHighAccuracy: true, timeout: 6000, maximumAge: 0 }
    //                 );
    //             });
    //         };
    
    //         while (attempts < MAX_TRIES) {
    //             let location = await fetchGeolocation();
    //             if (location) {
    //                 if (!bestLocation || location.accuracy < bestLocation.accuracy) {
    //                     bestLocation = location;
    //                 }
    //                 if (location.accuracy <= MIN_ACCURACY) break; // עוצרים אם הדיוק מספיק טוב
    //             }
    //             attempts++;
    //             await new Promise(resolve => setTimeout(resolve, 500)); // המתנה קצרה בין הניסיונות
    //         }
    
    //         if (!bestLocation || (bestLocation.latitude === 0 && bestLocation.longitude === 0)) {
    //             bestLocation = await getLocationFromGoogle();
    //         }
    
    //         setLocation(bestLocation);
    //         resolve(bestLocation);
    //     });
    // };
    
    // const getLocationFromGoogle = async () => {
    //     const API_KEY = "AIzaSyChYUm98Hn4cIkEn0BlpdrvIwIbIJID1-k";
    //     try {
    //         const response = await fetch(
    //             `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`,
    //             { method: "POST" }
    //         );
    //         const data = await response.json();
    //         if (data.location && data.accuracy) {
    //             return {
    //                 latitude: data.location.lat,
    //                 longitude: data.location.lng,
    //                 accuracy: data.accuracy, // משתמש ב-accuracy אם קיים
    //                 source: "Google Geolocation API"
    //             };
    //         } else {
    //             return {
    //                 latitude: data.location ? data.location.lat : 0,
    //                 longitude: data.location ? data.location.lng : 0,
    //                 accuracy: data.accuracy !== undefined ? data.accuracy : 0, // אם accuracy קיים, משתמשים בו, אחרת משתמשים ב-0
    //                 source: data.location ? "Google Geolocation API" : "Google Geolocation API Failure"
    //             };
    //         }
    //     } catch (error) {
    //         return {
    //             latitude: 0,
    //             longitude: 0,
    //             accuracy: 0,
    //             source: "Google Geolocation API Failure"
    //         };
    //     }
    // };

    const getLocation = () => {
        return new Promise(async (resolve) => {
            const MAX_TRIES = 5; // מקסימום ניסיונות
            const MIN_ACCURACY = 300; // דיוק מקסימלי רצוי
            let attempts = 0;
            let bestLocation = null; // ישמור את המיקום הכי טוב שמצאנו
    
            // בדיקה אם הדפדפן תומך ב-Geolocation
            if (!navigator.geolocation) {
                console.warn("Geolocation is not supported by this browser, falling back to Google API.");
                return resolve(await getLocationFromGoogle());
            }
    
            // בדיקה האם יש הרשאה
            const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
            if (permissionStatus.state === "denied") {
                console.warn("Geolocation permission denied, falling back to Google API.");
                return resolve(await getLocationFromGoogle());
            }
    
            const fetchGeolocation = () => {
                return new Promise((resolve) => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            resolve({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                accuracy: position.coords.accuracy,
                                source: "geolocation"
                            });
                        },
                        () => resolve(null), // במקרה של כשלון, מחזירים null
                        { enableHighAccuracy: true, timeout: 6000, maximumAge: 0 }
                    );
                });
            };
    
            while (attempts < MAX_TRIES) {
                let location = await fetchGeolocation();
                if (location) {
                    if (!bestLocation || location.accuracy < bestLocation.accuracy) {
                        bestLocation = location;
                    }
                    if (location.accuracy <= MIN_ACCURACY) break; // עוצרים אם הדיוק מספיק טוב
                }
                attempts++;
                await new Promise(resolve => setTimeout(resolve, 500)); // המתנה קצרה בין הניסיונות
            }
    
            if (!bestLocation || (bestLocation.latitude === 0 && bestLocation.longitude === 0)) {
                console.warn("Geolocation failed, falling back to Google API.");
                bestLocation = await getLocationFromGoogle();
            }
    
            resolve(bestLocation);
        });
    };
    
    // פונקציה לקבלת מיקום מגוגל
    const getLocationFromGoogle = async () => {
        const API_KEY = "AIzaSyChYUm98Hn4cIkEn0BlpdrvIwIbIJID1-k";
        try {
            const response = await fetch(
                `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`,
                { method: "POST" }
            );
            const data = await response.json();
    
            if (data.location && data.accuracy) {
                return {
                    latitude: data.location.lat,
                    longitude: data.location.lng,
                    accuracy: data.accuracy, // משתמש ב-accuracy אם קיים
                    source: "Google Geolocation API"
                };
            } else {
                return {
                    latitude: data.location ? data.location.lat : 0,
                    longitude: data.location ? data.location.lng : 0,
                    accuracy: data.accuracy !== undefined ? data.accuracy : 0, // אם accuracy קיים, משתמשים בו, אחרת משתמשים ב-0
                    source: data.location ? "Google Geolocation API" : "Google Geolocation API Failure"
                };
            }
        } catch (error) {
            return {
                latitude: 0,
                longitude: 0,
                accuracy: 0,
                source: "Google Geolocation API Failure"
            };
        }
    };
    
    



    useEffect(()=>{
        getDataSignatures()
        getLocation()
    },[])
 
    // useEffect(() =>
    // {
    //     console.log("long: " + location.longitude)
    //     console.log("lati: " + location.latitude)
    // } , [location])


    return (
        <div className='flex flex-col '>
           <h2 className='font-[#Actor] font-normal text-2xl text-[#333333] text-right'>דף חתימות נוכחות</h2>
           {stepsData.qul_id==null&&<p className='font-[#Poppins] font-semibold text-sm text-[#000000] text-right'>אין צורך בנוכחות כרגע </p>}
           {stepsData.qul_id&&<> 
            <p className='font-[#Poppins] font-semibold text-sm text-[#000000] text-right'>{dataCoures?.nameQ}</p>
            <p className='font-[#Actor] font-normal text-[12px] text-[#000000] text-right'>{dataCoures?.description}</p>
           
            <div className=" font-[#Actor] font-normal text-[12px] text-[#000000] text-right">
                {/* <p  className=''><b>מתי המפגש:</b> יום רביעי 12:00-14:00</p> */}
                <p><b>שם אשכול:</b> {dataCoures?.nameCluster}</p>
                <p><b>מספר אשכול:</b> {dataCoures?.numberCluster}</p>
                <p><b>שם של המדריך:</b> {dataCoures?.nameInsturcation}</p> 
                <p><b>מיקום:</b> {dataCoures?.place}</p>
                {stepsData.numberSign&&<p><b>מספר חתימה:</b> {stepsData?.numberSign}</p>}
                {stepsData.numberSign&&<p><b>תאריך חתימה:</b> {formatSignWindow(dataCoures?.signatureDates)}</p>}
            </div>
        

{/* 
            <p className="  mt-2 text-xs text-[#333]">
                רשימת המתנה לפוליסת האחריות המקצועית של לשכת ממוני הבטיחות.<br />
                ביום עיון נלמד על אילו תחומים חלה פוליסת הבטיחות בעבודה:<br />
                • חידוש חוקים, תקנות ונהלים רלוונטיים<br />
                • ניהול סיכונים ודרכי מניעה<br />
                • דגשים חשובים בניהול מערכות בטיחות<br />
                • ועוד...
            </p> */}

           
            <Stepper getLocation ={getLocation} location ={location} steps={stepsData} currentStep={stepsData?.numberSign?stepsData.numberSign:(stepsData?.indexSignatures)?.length} getDataSignatures={getDataSignatures} />
            </>}
        </div>
    )
}
            
export default Signatures