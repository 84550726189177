import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import ReDataTable from '../../../../ui/ReDataTable';
import ButtonNew from '../../../../ButtonNew';
import ModalEdit from '../../../../ModalEdit';
import RegistersMemberQualificationFilter from './RegistersMemberQualificationFilter';
import { MdAssignment, MdAssignmentReturned } from "react-icons/md"
import { IoTrashOutline } from 'react-icons/io5';
import { HeaderTable } from '../../../../HeaderTable';
import ModleNewRegistersMemberQualification from './ModleNewRegistersMemberQualification';
import Adding_Manual_Signature from './Adding_Manual_Signature';

const RegistersMemberQualification = ({ row }) => {
  // const GET_URL=`/admin/qualification/get_register_member_qualification/${row?.id}`
  const GET_Filter = `admin/qualification/get_numbers_members_filter_qualification/${row?.id}`
  const GET_URL_DELETE = `admin/qualification/detach_member_qualification/${row?.id}`
  const GET_URL = `admin/qualification/get_register_member_qualification/${row?.id}`
  const [dataFillter, setDataFillter] = useState({
    "member_number": ""
  })
  const [datafiler, setDatafiler] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState("");
  const [id, setId] = useState("");
    const [isSort, setisSort] = useState("number");
    const [orderBy, setorderBy] = useState("asc");

  const [fetchData, setFetchData] = useState([])
  const [loading, setloading] = useState(true)
  const [totalRows, setTotalRows] = useState(0);

  const handleColse = () => {
    setModalIsOpen("")
  }
  const getFilter = async () => {

    await axios.get(GET_Filter, {
    })
      .then(response => {
        setDatafiler(response?.data)
        // console.log(response?.data)

      })

  }
  useEffect(() => {
    getFilter()
  }, [])
  useEffect(() => {
    getDataTable()
  }, [isSort,orderBy])
  
  const handleDetele = async () => {

    await axios.get(`${GET_URL_DELETE}/${id.id}`).then(response => {
      if (response.status == 200) {
        getFilter()
        handleColse()
        getDataTable()
        // getPaying_customers(currentPage - 1)
        console.log("ok")
      }
      else {
        console.log("error")
      }
    })
  }



  const handleSort = () => {
    setisSort("sizeSign");
    setorderBy(prevOrder => prevOrder == "asc" ? "desc" : "asc");
};



 const getData = async (page, perPage) => {
    const response = await axios.get(GET_URL, {
        params: {
            ...dataFillter,
            "page": page,
            "size": perPage,
            "sortBy": isSort,
            "orderBy": orderBy, // נוסף שליחת סדר המיון
        }
    })
    if (response.status == "200") {
      const response1 = (response?.data?.resMemberRegisters)
      const response2 = (response?.data?.totalElements);
      return [response1, response2];
    }
    const response1 = []
    const response2 = []
    return [response1, response2];
  };

  // const getDataTable = async (page, perPage) => {



  //   const [result1, result2] = await getData(page, perPage)
  //   setFetchData(result1);
  //   setTotalRows(result2);
  //   setloading(false)
  //   console.log(fetchData)
  // };
  const getDataTable = useCallback(async (page, perPage) => {
    
    console.log("🔄 getDataTable הופעל", { page, perPage });
    const [result1, result2] = await getData(page, perPage);
    console.log("📊 נתונים מהשרת:", result1);
    setFetchData([...result1]);
    setTotalRows(result2);
    setloading(false);
}, [dataFillter, isSort, orderBy]);  // תלוי בנתוני הסינון בלבד

useEffect(() => {
  console.log("🆕 fetchData עודכן:", fetchData);
}, [fetchData]);

  const columns = [
    {
      name: "פעולות",
      // grow: 2,
      cell: (row) => <div className='w-full'>

        <button title="אפשרויות נוספות" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
            console.log("🟢 row שנלחץ:", row);
          setId(row)
          setModalIsOpen("ADD")
        }}><MdAssignment /></button>
        <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
          setId(row)
          setModalIsOpen("Delete")
        }}><IoTrashOutline /></button></div>,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "מספר תעודה",
      // grow: 2,
      cell: (row) => row?.certificateNumber,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "תעודה",
      // grow: 1,
      cell: (row) => {
        if (row?.urlCertificate)
          return (<a title="הורד תעודה" className='p-0'
            href={`https://archivebureau.s3.eu-west-3.amazonaws.com/${row.urlCertificate}`}
          ><button className=' border border-gray-500 p-1 opacity-25 hover:opacity-100' ><MdAssignmentReturned /></button></a>
          )
        else { return ("לא קיימת תעודה") }
      },
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        // width: '100px'
      },

    },



    {
      // name: <div className='w-fit' onClick={()=>alert("asdasd")}>"מספר חתימות"</div>,
      name: <div className='w-fit cursor-pointer' onClick={handleSort}>
      מספר חתימות {orderBy === "asc" ? "↑" : "↓"}
  </div>,
  grow: 1.5,
  cell: (row) => row?.numberSignatures?.length,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        // width: '100px'
      },
       
    },
    
    {
      name: "טלפון ראשי",
      // grow: 2,
      cell: (row) => row?.mainPhone,
      style: {
        // fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "דוא\"ל",
      grow: 3,
      cell: (row) => row?.email,
      style: {
        // fontSize: '16px',
        justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "סטטוס חבר",
      // grow: 2,
      cell: (row) => row?.status?.statusDescription,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "שם משפחה",
      // grow: 2,
      cell: (row) => row?.lastName,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "שם פרטי",
      // grow: 2,
      cell: (row) => row?.firstName,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        // width: '100px'
      },
       
    },
    {
      name: "ת.ז",
      // grow: 2,
      cell: (row) => row?.idNumber,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        width: '100px'
      },
       
    },
    {
      name: "מספר חבר",
      // grow: 2,
      cell: (row) => row?.number,
      style: {
        // fontSize: '16px',
        // justifyContent: 'center',
        width: '100px'
      },
       
    },




  ];

  return (
    <div style={{ direction: "ltr" }}>
      <RegistersMemberQualificationFilter setDataFillterout={setDataFillter} row={row} datafiler={datafiler}  />
      <HeaderTable active={"ממונים רשומים ליום הכשירות"} setModalIsOpen={setModalIsOpen} />
      <ReDataTable columns={columns} getDataTable={getDataTable}  fetchData={[...fetchData]}  loading={loading} totalRows={totalRows} dataFillter={dataFillter} />
      {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}
      {modalIsOpen == "New" && <ModleNewRegistersMemberQualification showModal={modalIsOpen == "New"} handleColse={handleColse} row={row?.id} getDataTable={getDataTable}> </ModleNewRegistersMemberQualification>}
      {modalIsOpen == "ADD" && <Adding_Manual_Signature showModal={modalIsOpen == "ADD"} handleColse={handleColse} fetchData={id} id={row.id} getDataTable={getDataTable}> </Adding_Manual_Signature>}
    </div>
  )
}

export default RegistersMemberQualification