import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ImCancelCircle } from "react-icons/im";

import axios from 'axios'
import ModalMyTrainingDays from './ModalMyTrainingDays'
import { useParams } from 'react-router-dom'
import Cancel from './Cancel';
const GET_URL = 'dashboard/member/my_qualification_day'
const GET_URLDELETE = 'dashboard/member/detach_qualification_day'
const MyTrainingDays = () => {
    const { corseId } = useParams();


   
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
 
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure 2 digits for month
        const day = ('0' + date.getDate()).slice(-2); // Ensure 2 digits for day
        return `${day}/${month}/${year}`;
    };
    
    
    
    const getTrainingDays = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
            params: {
                
                "page": page
                , "size": perPage,


            }
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data)
                    // setdataForfilterScrach(response?.data)
                    // setTotalRows("");
                }
            })
        setloading(false)

    }
    useEffect(() => {
        getTrainingDays(currentPage - 1)
        if(corseId)
            {
                setModalIsOpen("OPEN") 
            }
    }, [currentPage, perPage])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }
    const handlePageChange = (page) => {
        // fetchUsers(page);
        // getData(page);
        setCurrentPage(page);
    };
    const handleColse = () => {
        setModalIsOpen("")
    }
    const columns = [
        {
            name: "פעולות",

            // cell: row => row?.mySelfQualificationDto?.statusQualification?.id=="5"&&<div className='w-full'>
            cell: row => row?.mySelfQualificationDto?.statusQualification?.id=="5"&&row?.isCanDetach&&<div className='w-full'>
                {/* <a title="ייצא תעודה"  className='p-0'
                        href={`https://archivebureau.s3.eu-west-3.amazonaws.com/${row.imgCertificate}`}
             ><button className=' border border-gray-500 p-1 opacity-25 hover:opacity-100' ><MdAssignmentReturned /></button></a> */}
                {/* <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    // setId(row)
                    // setModalIsOpen("Delete")
                }}><MdAssignment /></button> */}
                <button title="ביטול קורס" className='  ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    setId(row?.mySelfQualificationDto)
                    setModalIsOpen("Delete")
                }}><ImCancelCircle size={16} /></button>
                {/* <button className=' border border-gray-500 p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button> */}
            </div>,


            style: {
                fontSize: '10px',
                justifyContent: 'center',



                // padding: '2px'
                // width: '100%'
            },
            grow: 1,


        },
        {
            name: "תעודה",
            // grow: 2,
            cell: (row) => row?.reportCertificateNumberDto?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             
        },

        {
            name: "סטטוס הכשירות",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.statusQualification?.statusDescription,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
             
        },
        // {
        //     name: "תאריך הרשמה",
        //     grow: 1,
        //     selector: (row) => formatDate(row?.dateRegister),
        //     style: {
        //         fontSize: '10px',
        //         justifyContent: 'center',
        //         // width: '500px'
        //     },
        // 
        // },
        {
            name: "שם המדריך",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.instructor?.frame_manager,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             
        },
        {
            name: "תאריך",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.startDate? formatDate(row?.mySelfQualificationDto?.startDate):"",
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             
        },
        {
            name: "שם יום הכשירות",
            // grow: 3,
            cell: (row) =>  row?.mySelfQualificationDto?.name,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             
        },


        {
            name: "מספר יום הכשירות",
            // grow: 3,
            cell: (row) => row?.mySelfQualificationDto?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             


        },
        {
            name: "אשכול",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.cluster?.name,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             


        },
        {
            name: "מספר אשכול",
            // grow: 3,
            cell: (row) => row?.mySelfQualificationDto?.cluster?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
             


        },



    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                // whiteSpace: 'normal',
                // overflow: 'visible',
                // textOverflow: 'clip',
                // maxWidth: 'none', // Allow headers to expand as needed
                // padding: '10px'
                // ,
                fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                paddingLeft: '0px',
                paddingRight: '0px'
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    // padding: '10px'
                    // ,
                    //       paddingLeft:'0px',
                    // paddingRight:'0px',
                    //     borderRightStyle: 'solid',
                    //     borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }



    const handleDetele = async () => {

        await axios.get(`${GET_URLDELETE}/${id?.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getTrainingDays()
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }





  return (
<div className='bg-white p-2'>
<div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-2 font-sans grid grid-cols-2 rounded-t-lg border '>
<div className='flex justify-end'>
<button className=' mr-3'>
    <p>ייצא</p>
</button>
<button className='bg-[#1e8e2b] border border-[#1a7925] text-white w-fit h-fit flex justify-self-end self-center p-2 px-3 rounded-lg'
 onClick={()=>{setModalIsOpen("OPEN")}}>
  חדש+
</button>

</div>
<p>ימי הכשרות שלי</p>


</div>
{(modalIsOpen=="OPEN")&&<ModalMyTrainingDays corseId={corseId} handleColse={handleColse} setModalIsOpen={setModalIsOpen}  getTrainingDaysMain={()=>getTrainingDays(currentPage - 1)}></ModalMyTrainingDays>}
<DataTable columns={columns} data={fetchData} pagination customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border' 
                >
            </DataTable>
{(modalIsOpen=="Delete")&&<Cancel handleColse={handleColse} handleDetele={handleDetele} ></Cancel>}

</div>
  )
}

export default MyTrainingDays