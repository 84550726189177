import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import NavBar from '../copmponents/NavBar'
import UserProfile from '../copmponents/UserProfile'

const Mobile = () => {
  const [loading, setLoading] = useState(false)
  setTimeout(() => { setLoading(true) }, 1000)
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   if (!isMobile) {
  //     navigate("/");  // אם המשתמש לא במובייל, להפנות אותו לדף הרגיל
  //   }
  // }, [navigate]);
  return (
    // <div className='h-svh p-4 pb-16'>
    //   {loading&&<UserProfile/>}
    //        <Outlet/>
    //     <NavBar/>
    //     </div>
    <div className="h-svh flex flex-col">
      {/* חלק עליון - פרופיל (קבוע) */}
      <div className="p-2 bg-white shadow-md">
        <UserProfile />
      </div>

      {/* חלק אמצעי - תוכן משתנה */}
      <div className="flex-grow overflow-auto pb-20 p-4">
        <Outlet />
      </div>

      {/* חלק תחתון - ניווט (קבוע) */}
      <div className=" fixed bottom-0 left-0 w-full bg-white shadow-lg">
        <NavBar />
      </div>
    </div>

  )
}

export default Mobile