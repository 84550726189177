import React, { useState } from 'react'
import axios from 'axios'
import ReDataTable from '../../../ui/ReDataTable';
import { HeaderTable } from '../../../HeaderTable';
import { formatOnlyTime } from '../../../../util/formatsForDate';

const CollectingSignatures = ({row}) => {
    // const GET_URL=`admin/qualification/get_register_member_qualification/${row?.id}?sortBy=name`
    const GET_URL=`admin/qualification/get_show_sign_qualification/${row.id}?sortBy=name`
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0); 

    const getDataTable = async (page,perPage) => {


        
      const [result1, result2] = await getData(page,perPage)
      setFetchData(result1);
      setTotalRows(result2); 
      setloading(false)
      console.log(fetchData)
    };

    const getData = async (page,perPage) => {
        const response= await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            if (response.status=="200") 
             {
                const response1 = (response?.data)
                const response2= (response?.data?.totalElements);
                return [response1, response2];
            }
            const response1 = []
            const response2= []
            return [response1, response2];
      };
      const columns = [
       
       
       
        {
          name: "חתימה",
          grow: 2,
          cell: (row) => <img src={row?.linkSignature?`https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.linkSignature}`:""}/>,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            // width: '100px'
          },
           
        },
        {
            name: "מרחק (מטרים)",
            grow: 2,
            cell: (row) => row?.distance,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
        {
            name: "Latitude",
            grow: 2,
            selector: (row) => row?.type=="auto"?row?.latitude:"חתימה ידנית",
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
          {
            name: "Longitude",
            grow: 2,
            cell: (row) => row?.type=="auto"?row?.longitude:"חתימה ידנית",
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
          {
            name: "שעת חתימה",
            grow: 2,
            cell: (row) => row?.timeSignature?formatOnlyTime(row?.timeSignature):"",
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
          {
            name: "שם משפחה",
            grow: 2,
            cell: (row) => row?.lastName,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
          
          {
            name: "שם פרטי",
            grow: 2,
            cell: (row) => row?.firstName,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
          {
            name: "ת.ז.",
            grow: 1,
            cell: (row) => row?.memberIdentity,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
          {
            name: "מספר חבר",
            grow: 2,
            cell: (row) => row?.memberNumber,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              // width: '100px'
            },
             
          },
       
    
    
    
      ];

    return (
        <div style={{direction:"ltr"}}>
            <HeaderTable active={"ריכוז חתימות"}/> 
            <ReDataTable columns={columns} getDataTable={getDataTable} fetchData={fetchData} loading={loading} totalRows={totalRows}/>
        </div>
    )
}

export default CollectingSignatures