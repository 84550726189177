import React, { useEffect, useState,useRef } from 'react'
import axios from 'axios'
import Modall from '../../Modall';
import { FaUpload } from 'react-icons/fa';

const GET_URL = 'admin/qualification/title_certificate'


const ModalNewTitle_certificate = ({ showModal, children, handleColse, getDepartment, row = "" }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const fileInputRef = useRef(null);


    const handleTakePhotoClick = () => {
        // Trigger the file input element to open the camera
        fileInputRef.current.click();
      };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setFileSize((selectedFile.size / 1024).toFixed(2) + ' KB');

            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewUrl(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const [formData, setFormData] = useState({
        title: '',
        caption: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.title) newErrors.title = 'שדה חובה';
        if (!formData.caption) newErrors.caption = 'שדה חובה';
        if (!file) newErrors.logo = 'שדה חובה';


        return newErrors;
    };

    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            console.log(formData)
            addNewCertificate()
        }
    };

    // useEffect(() => {
    //     if (row) {
    //         setFormData(
    //             {
    //                 caption: row.caption,
    //                 title: row.title,
    //             })
    //             setFileName(row.logo)
    //         setPreviewUrl('https://archivebureau.s3.eu-west-3.amazonaws.com/'+row.logo)
    //     }
    // }, [])




    const addNewCertificate = async () => {
        const formData1 = new FormData();
        formData1.append('CertificateDetails', JSON.stringify({ 
            "title": formData.title,
            "caption": formData.caption,
           }));
        formData1.append('img_logo', file);
        const response = await axios.post(GET_URL,formData1   
        ,    
         {
            headers: { 'Content-Type': 'multipart/form-data' }
        })

        if (response?.status == 200) {
         
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }

    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>


                <form className='w-full p-5 flex flex-col  text-center text-sm'>
                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className=' w-1/2 border  text-right'
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                        />
                        <label htmlFor="title " className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שם הכותרת</p></label>

                    </div>
                    {errors.title && <span className="error">{errors.title}</span>}

                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="caption"
                            name="caption"
                            value={formData.caption}
                            onChange={handleChange}
                        />
                        <label htmlFor="caption" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>כיתוב הכותרת</p></label>

                    </div>
                    {errors.caption && <span className="error">{errors.caption}</span>}

                    <div className="w-full pt-2 flex justify-end">

                        <p
                            className='border w-1/2 text-center'
                            type="text"
                            id="logo"
                            name="logo"
                            
                            disabled
                        >
                              {fileName}
                        </p>
                        <label htmlFor="logo" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>לוגו</p></label>

                    </div>
                    {errors.logo && <span className="error">{errors.logo}</span>}

                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="fileInput" style={{ display: 'block' }}>לוגו:</label>
                        <input type="file" id="fileInput" name="fileInput"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            accept="image/*"
                        // style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        {file && (
                            <div className='flex flex-col items-center'>
                                <img src={previewUrl} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                <p>{fileName}</p>
                                <p>{fileSize}</p>
                            </div>
                        )}
                    </div>

                    <button type="button" className= 'flex w-fit place-self-center p-2 items-baseline bg-[#4CAF50] text-white border-none broder  cursor-pointer' onClick={handleTakePhotoClick}>
                        <p className='mr-2'>העלת קובץ</p>
                        <FaUpload/>
                        
                    </button>

                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        {
                            // row ? handleEditCertificate(row.id) :
                             handleSubmit()
                        }
                        // handleSubmit()


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>
    )
}

export default ModalNewTitle_certificate