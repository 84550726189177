import React, { useState, useEffect } from 'react'
import { HeaderTable } from '../../HeaderTable'
import ReDataTable from '../../ui/ReDataTable'
import {formatDateIL} from "../../../util/formatsForDate"
import axios from 'axios'


const PurchaseArchiveMembers = ({row}) => {
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
  
      const GET_URL=`admin/member/get_purchase_history/${row?.id}`
      const getData = async (page,perPage) => {
          const response= await axios.get(GET_URL, {
              params: {
                  "page": page
                  , "size": perPage,
              }
          })
              if (response.status=="200") 
               {
                console.log(response)
                  const response1 = (response?.data?.resBillingAndTransactionList)
                  const response2= (response?.data?.totalElements);
                  return [response1, response2];
              }
              const response1 = []
              const response2= []
              return [response1, response2];
        };
  
  const getDataTable = async (page,perPage) => {
  
  
          
      const [result1, result2] = await getData(page,perPage)
      setFetchData(result1);
      setTotalRows(result2); 
      setloading(false)
      console.log(fetchData)
    };

    const columns = [
            {
                name: "תשלום כולל",
                grow: 2,
                cell: (row) => row?.amountPay+"₪",
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "שימוש בזיכוי",
                grow: 1,
                selector: (row) => row?.creditUsed,
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "קבלה",
                grow: 1,
                cell: (row) => row?.type=="cardcom"?row?.transactionId:"",
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "חבר משלם",
                grow: 1,
                cell: (row) => row?.type=="pay_customer"?row?.payingCustomer?.name:"",
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "קוד מוצר",
                grow: 1,
                cell: (row) => row?.productCode_order,
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "שם מוצר",
                grow: 1,
                cell: (row) => row?.productName_order,
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "סטטוס יום כשירות",
                grow: 1,
                cell: (row) => row?.qualificationDay?.statusQualification?.statusDescription,
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
              {
                name: "תאריך יום כשירות",
                grow: 1,
                selector: (row) => formatDateIL(row?.qualificationDay?.startDate),
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
              {
                name: "שם יום הכשירות",
                grow: 2,
                cell: (row) => row?.qualificationDay?.name,
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
            {
                name: "תאריך רכישה",
                grow: 1,
                cell: (row) => row?.execution_date?formatDateIL(row?.execution_date):"",
                style: {
                  // fontSize: '16px',
                  // justifyContent: 'center',
                  // width: '100px'
                },
                 
              },
        
          ];
    
          const customStyles = {
            // header: {
            //     style: {
            //         justifyContent: 'center',
    
            //     },
            // },
            id: {
                style: {
                    '&:2': { backgroundColor: '#EEEEEE' },
                }
            },
            // headRow: {
            //     style: {
                  
            //         justifyContent: 'center',
            //         borderTopStyle: 'solid',
            //         borderTopWidth: '1px',fontSize: '14px',
                    
            //         // borderTopColor: defaultThemes.default.divider.default,
            //     },
            // },
            headCells: {
                style: {
    
                    fontSize: '14px',    
                    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
    
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                        // borderRightColor: defaultThemes.default.divider.default,
                    },
                    justifyContent: 'center',
                },
            },
            rows: {
                style: {
                    backgroundColor: '#FFFFFF',
                    '&:active': {
                        backgroundColor: '#EEEEEE',
                    },
                },
            }, }
  return (
    <div style={{direction:"ltr"}} >
            <HeaderTable active={"ארכיון רכישות"}/>
             <ReDataTable columns={columns} getDataTable={getDataTable} fetchData={fetchData} loading={loading} totalRows={totalRows}/>
         
        </div>
  )
}

export default PurchaseArchiveMembers