import React, { useState } from 'react'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom';

const ModalConfirm = ({ course, idCorse, setModalIsOpen, handleColse, getTrainingDaysMain }) => {
    const Url = `dashboard/member/request_register_qualification/${idCorse}/SELF`
    // const navigate=useNavigate();
    // const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
    // const [urlCardCom, seturlCardCom] = useState("");
    const OverToPay = async () => {

        // await axios.post(`${Url}`, {
        //         headers: { 'Content-Type': 'application/json' }
        //     }).then(response => {
        //         if (response?.status == 200) {
        //             // setnamecalss("")
        //             // console.log(response)
        //             // seturlCardCom(response?.data?.urlPage)
        //             // setModalConfirmIsOpen(true)
        //             console.log(response)
        //             // window.location.href =response?.data?.urlPage                   
        //             // window.location.href ="http://localhost:3000/registrationTrainingDays"                   
        //         }
        //     })


        try {
            await axios.post(`${Url}`, {
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {

                const data = response?.data;
                console.log(data)
                if (response?.status == 200) {
                    if (typeof data === 'string') {
                        //                         // אם התגובה היא הודעה (string)

                        alert(`Message: ${data}`);
                        getTrainingDaysMain()
                        handleColse()
                    }
                    else {
                        //                         // אם התגובה היא אובייקט (כמו SendProfileIdDto)
                        // alert("Object:", data);
                        // console.log(response.data)
                        // if (response?.status == 200) {
                        // setnamecalss("")
                        // console.log(response)
                        // seturlCardCom(response?.data?.urlPage)
                        // setModalConfirmIsOpen(true)
                        // console.log(response)
                        window.location.href = response?.data?.urlPage
                        // window.location.href ="http://localhost:3000/registrationTrainingDays"                   
                    }
                    // }
                    //  else{
                    //     console.log("שגיאה בבקשה:")
                    //     // alert("שגיאה בבקשה:", data?.message)
                    }
                })

        } catch (error) {
            console.log(error?.response?.data?.message)
            alert(`שגיאה : ${error?.response?.data?.message}`);
            // setErrMess(error?.response?.data?.message || "שגיאה בשליחת הנתונים");
            // await onError();
        }
    };

    return (
        <div className='absolute top-0  min-h-screen z-10 left-0 flex flex-col items-center pt-2 w-full  bg-[#FDFFFC]/50 '
            onClick={() => {
                // handleColse()
            }}>
            <div className='bg-white w-[40%]   rounded-lg   divide-y-2 divide-slate-500 border-0 drop-shadow-2xl shadow-2xl border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex  border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        setModalIsOpen(false)
                        //   
                    }} >x</button>
                </div>


                <div className='p-2 text-center' >
                    <div className='text-red-500 text-sm font-medium p-2'>לחץ על הריבוע הריק לסימון V על מנת לאשר את ההצהרת ההשתפות ביום העיון
                    </div>
                    <div className='grid grid-cols-4 ' >
                        <label className='grid col-span-1 ml-2 text-sm  font-bold'>הצהרת השתתפות</label>
                        <div className='grid col-span-3 '>
                            <div className='flex'>
                                <input type='checkbox'></input>
                                <span className='px-2'>אני מצהיר כי ידועה לי חובת הנוכחות במשך יום העיון. כמו כן הובא לידיעתי כי אין להשתמש בטלפון סלולרי במהלך ההרצאות ביום הכשירות הפרונטלי. בנוסף הנני מתחייב שלא לבצע שימוש בטלפון הנייד בזמן נהיגה. ובנוסף קיימת חובת חתימה 3 פעמים כתנאי לקבלת אישור כשירות, באמצ</span>

                            </div></div>
                    </div>


                </div>
                <div className='flex justify-between text-white p-2'>
                    <button className='bg-blue-900 text-xl p-2 rounded-md' onClick={() => { setModalIsOpen(false) }}>בטל</button>
                    <button className='bg-[#1e8e2b] text-xl p-2 rounded-md' onClick={() => {
                        OverToPay()
                    }}>שלח</button>
                </div>
                {/* {modalConfirmIsOpen&&<iframe className='absolute h-screen' src={urlCardCom}></iframe>} */}
                {/* {modalConfirmIsOpen&&<navigate} */}
            </div>

        </div>
    )
}

export default ModalConfirm