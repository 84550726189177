import React, { useState } from 'react'
import { IconContext } from 'react-icons';
import { TfiKey } from "react-icons/tfi";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // אייקונים של עין
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie"
import axiosAuth from '../Api/axiosAuth';
import { useAuth } from '../Context/AuthProvider';
const LOGIN_URL = '/auth/login';

const CardLogin = () => {
    const [infpError, setInfpError] = useState(false);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // משתנה למצב הצגת הסיסמה
    const navigate = useNavigate();
    const cookies = new Cookies();
    const { auth, setAuth } = useAuth();

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev);
    };

    const auth_submit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosAuth.post(LOGIN_URL, {
                'userName': user, 'password': password
            }, { withCredentials: true });

            localStorage.setItem('access_token', response?.data['access_token']);
            cookies.set('isAllow', "1", { path: '/', maxAge: 31536000 }, { httpOnly: true }, { SameSite: "None" });
            cookies.set('role', response?.data['roles'][0], { path: '/', maxAge: 31536000 }, { httpOnly: true }, { SameSite: "None" });

            axiosAuth.defaults.headers.common['Authorization'] = `Bearer ${response?.data['access_token']}`;
            setAuth(response?.data['refersh_token']);
            setPassword('');
            setUser('');
            setInfpError(false);

            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            navigate(isMobile ? '/Mobile' : '/');
        } catch (err) {
            setInfpError(true);
        }
    };

    return (
        <form className='bg-white flex flex-col w-[400px] max-sm:w-[80%] border-2 border-solid border-transparent rounded-md mt-10' onSubmit={auth_submit} style={{ direction: "rtl" }}>
            <label className='bg-[#F7F7F7] w-full py-[1rem] text-[22px] font-normal'>התחברות</label>
            <div className='p-8'>
                {infpError && <p className='mt-0 text-red-700'>המשתמש או הסיסמה לא נכונים</p>}

                <div className='flex w-full'>
                    <IconContext.Provider value={{ color: "#555", size: 35 }}>
                        <FaRegUser className='bg-[#eee] border-[1px] rounded-sm border-solid p-2 border-[#ccc]' />
                    </IconContext.Provider>
                    <input
                        className='w-full border-[1px] rounded-sm pr-2 border-solid border-[#ccc]'
                        type="text"
                        placeholder='שם משתמש'
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                    />
                </div>

                <div className='flex mt-4 relative'>
                    <IconContext.Provider value={{ color: "#555", size: 35 }}>
                        <TfiKey className='bg-[#eee] border-[1px] rounded-sm border-solid p-2 border-[#ccc]' />
                    </IconContext.Provider>
                    
                    <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete='off'
                        placeholder='סיסמה'
                        className='w-full border-[1px] rounded-sm pr-2 border-solid border-[#ccc]'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    
                    <button
                        type="button"
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-600"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <AiOutlineEye size={24} /> : <AiOutlineEyeInvisible size={24} />}
                    </button>
                </div>

                <button className='bg-[#0D406A] w-full text-lg rounded border-[#0a3254] hover:bg-[#07253e] hover:border-[#04121e] p-2 mt-8 text-white'>
                    התחבר
                </button>
                <p className="mt-4 text-center">
  אין לך חשבון?
  <Link to="/signup" className="text-[#0D406A] font-semibold hover:underline ml-1">
    הירשם עכשיו
  </Link>
</p>
            </div>
        </form>
    );
}

export default CardLogin;
