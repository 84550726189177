import React, { useRef, useState } from "react";
import './SignatureModal.css';
import BtnSendDis from '../../assets/senddis.png'
import BtnSendAct from '../../assets/sendactive.png'
const SignatureModal = ({ onClose, onSave, setdataSign, setFile }) => {
  const canvasRef = useRef(null);
  const isDrawing = useRef(false);
   const [loading, setLoading] = useState(false)
  const [isSigned, setIsSigned] = useState(false); // סטייט לבדיקה אם יש חתימה

  const startDrawing = (e) => {
    e.preventDefault();
    isDrawing.current = true;
    const ctx = canvasRef.current.getContext("2d");
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (e.clientX || e.touches[0].clientX) - rect.left;
    const y = (e.clientY || e.touches[0].clientY) - rect.top;

    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const draw = (e) => {
    e.preventDefault();
    if (!isDrawing.current) return;

    const ctx = canvasRef.current.getContext("2d");
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (e.clientX || e.touches[0].clientX) - rect.left;
    const y = (e.clientY || e.touches[0].clientY) - rect.top;

    ctx.lineTo(x, y);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.stroke();

    setIsSigned(true); // ברגע שמתחילים לצייר, החתימה נחשבת כשישנה
  };

  const stopDrawing = () => {
    isDrawing.current = false;
  };

  const clearCanvas = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsSigned(false); // אחרי ניקוי החתימה, הכפתור שוב יהיה Disabled
  };

  const handleSave = async () => {
    if (!isSigned) return; // מניעת שליחה אם אין חתימה

    const canvas = canvasRef?.current;
    // const signature = canvas.toDataURL("image/png");
    // onSave(signature);

    const dataURL = canvas.toDataURL("image/png");
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    await onSave(blob);

    // setdataSign(true);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-btn" onClick={onClose}>
          ✕
        </button>
        <canvas
          ref={canvasRef}
          width={250}
          height={200}
          className="signature-canvas"
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        ></canvas>
        <div className="button-group">
          {/* <button className="save-btn" onClick={handleSave} disabled={!isSigned}>
            שליחה
          </button> */}
          <button type="button" disabled={loading||!isSigned} className={` ${isSigned&& !loading ? "send-button" : "send-button-dis "} `} onClick={async () => {
                  setLoading(true)
                  await handleSave()
                  setLoading(false)
                } }
                >
                  {loading ? (
                    // אייקון טעינה - ספירלה מסתובבת
                    <span className="flex items-center">
                      <svg className="animate-spin h-10 w-10 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>
                    </span>
                  ) : (
          
                    <>
                      <img src={isSigned ? BtnSendAct : BtnSendDis} alt="" />
                      <span className={`${isSigned ? "send-text" : "send-text-dis"}`}>שליחה</span>
                    </>)}
                </button>
          <button className="clear-btn" onClick={clearCanvas}>
            ניקוי
          </button>
        </div>
      </div>
    </div>
  );
};
 

export default SignatureModal