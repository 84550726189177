export function mobileLoader() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
        return Response.redirect("/", 302); // מפנה אוטומטית לדף הראשי אם זה לא מובייל
    }
    return null; // אם המשתמש במובייל, ממשיכים כרגיל
}

export function desktopLoader() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // אם המשתמש במובייל, נוודא שהוא יגיע לגרסה של המובייל
    if (isMobile) {
        return Response.redirect("/Mobile", 302); // הפנייה לדף הראשי של הדסקטופ
    }
    return null
}

export function desktopregistrationTrainingDaysLoader({params}) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // const corseId = params?.corseId ? `/${params.corseId}` : "";
    const corseId = params?.corseId? +params.corseId : "";
    // console.log(params)
    // אם המשתמש במובייל, נוודא שהוא יגיע לגרסה של המובייל
    if (isMobile) {
        // const currentUrl = window.location.pathname;
        // const corseId = currentUrl.split('/').pop();
        return Response.redirect(`/Mobile${Number.isInteger(+corseId)?"/"+corseId:''}`, 302); // הפנייה לדף הראשי של הדסקטופ
    }
    if(!Number.isInteger(corseId))
        return Response.redirect(`/registrationTrainingDays`, 302);
return null
}


export function mobileregistrationTrainingDaysLoader({params}) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const corseId = params?.corseId? +params.corseId : "";
    // אם המשתמש במובייל, נוודא שהוא יגיע לגרסה של המובייל
    if (!isMobile) {
        const currentUrl = window.location.pathname;
        const corseId = currentUrl.split('/').pop();
        return Response.redirect(`/registrationTrainingDays/${corseId}`, 302); // הפנייה לדף הראשי של הדסקטופ
    }
    if(!Number.isInteger(corseId))
        return Response.redirect(`/Mobile`, 302);
    return null
}
















      // אם זה לא מובייל, נוודא שהוא יועבר לגרסה של הדסקטופ

//   export function desktopLoader() {
//     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

//     // אם המשתמש במובייל, נוודא שהוא יגיע לגרסה של המובייל
//     if (isMobile) {
//       return null; // אם זה מובייל, נמשיך לגרסה של המובייל
//     } else {
//       // אם זה לא מובייל, נוודא שהוא יועבר לגרסה של הדסקטופ
//       return Response.redirect("/", 302); // הפנייה לדף הראשי של הדסקטופ
//     }
//   }