import axios from "axios";
import Cookies from "universal-cookie";

axios.defaults.baseURL = 'https://backapi.ismbonline.org/api/';

const cookies = new Cookies();
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

// בקשת JWT לכל בקשה רגילה, אבל מוחקים אותו מה-refresh
axios.interceptors.request.use(
    (config) => {
        if (config.url.includes('refreshtoken')) {
            delete config.headers['Authorization']; // לא שולחים access token ל-refresh
        } else {
            const token = localStorage.getItem('access_token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Interceptor עם תור
axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // מוסיפים את הבקשה לתור עד שה-refresh יסתיים
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then((token) => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return axios(originalRequest);
                }).catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const refreshRes = await axios.post('auth/refreshtoken', { "token": "123" }, { withCredentials: true });

                if (refreshRes.status === 200) {
                    const newToken = refreshRes.data['access_token'];
                    localStorage.setItem('access_token', newToken);
                    processQueue(null, newToken); // שולח את כל הבקשות מהתור מחדש עם טוקן חדש

                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    return axios(originalRequest);
                } else {
                    throw new Error('Refresh token failed');
                }
            } catch (err) {
                processQueue(err, null);
                clearSession();
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        } else if (error.response?.status === 403) {
            clearSession();
        }

        return Promise.reject(error);
    }
);

function clearSession() {
    cookies.remove('role');
    cookies.remove('isAllow');
    cookies.remove('refresh-token');
    localStorage.removeItem('access_token');
    axios.defaults.headers.common['Authorization'] = null;
    window.location.href = '/login';
}

