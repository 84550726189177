import React from 'react'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //     if (isMobile) {
    //         navigate("/Mobile"); // הפניה לגרסת מובייל
    //     } else {
    //         navigate("/"); // הפניה לגרסת מחשב
    //     }
    // }, [navigate]);

    useEffect(() => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      setTimeout(() => {
          navigate(isMobile ? "/Mobile" : "/");
      }, 2000); // מחכה 2 שניות לפני ההפניה
  }, [navigate]);

    return (
        <div className="flex justify-center items-center h-screen bg-red-200">
            <h1 className="text-2xl font-bold">אירעה שגיאה, מעביר אותך...</h1>
        </div>
    );
};

export default ErrorPage;