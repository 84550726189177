import React from 'react'
import { FiAlertTriangle } from "react-icons/fi";

const Cancel = ({handleColse,handleDetele}) => {
    return (
        <div className=' fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center py-4 w-full min-h-screen drop-shadow-2xl shadow-2xl bg-slate-500/40 overflow-y-auto'>
            <div className=' w-3/4 divide-y-2  divide-slate-500 bg-white rounded-md'>
                <div className='w-full  flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-200 rounded-full   hover:bg-slate-500' onClick={handleColse}
                    // setShowModal(false)

                    >x</button>
                </div>
                <div className='w-full p-5 flex flex-col items-center '>
                <FiAlertTriangle size={60} color='red'/>
                    <p className='w-full text-center'>?האם אתה בטוח שברצונך לבטל את הקורס</p>

                </div>

                <div className='w-full p-2   flex justify-center'>
                    <button className='border bg-red-500 hover:bg-red-950 w-11 p-1 px-3 rounded-lg' onClick={handleDetele}>כן</button>
                    <button className='border bg-slate-300 hover:bg-slate-500 w-11 p-1 px-3 rounded-lg' onClick={handleColse}
                    >בטל</button>
                </div>
            </div></div>
    )
}

export default Cancel