import React, { useEffect, useState } from 'react'

import calendar from '../../util/calendar.png'
import Post from '../copmponents/Post'
import SignatureModal from '../copmponents/SignatureModal'
import RegistrationCourse from '../copmponents/RegistrationCourse'
import PaymentModal from '../copmponents/PaymentModal'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const Home = () => {
    const GET_URL = 'dashboard/member/open_qualification_day_register'
    const [fetchData, setFetchData] = useState([])
   
     const { corseId } = useParams();
    const getTrainingDays = async () => {

        // if (!corseId)
        //     await axios.get(GET_URL, {
        //         // params: {
        //         //     "page": page
        //         //     , "size": perPage,
        //         // }
        //     })
        //         .then(response => {
        //             if (response.status == 200) {
        //                 setFetchData(response?.data)
        //             }
        //         })
        // else
        // {
        await axios.get(GET_URL, {
            params: {
                // "qId": corseId
                //     , "size": perPage,
            }
        }).then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data)
                    // setShow(true)
                }
            })
    }
        useEffect(() => {
            getTrainingDays()
        }, [])
        //   const [modalIsOpen,setModalIsOpen] = useState(-1);  
        //   const handleColse = () => {
        //     console.log("first")
        //     setModalIsOpen(-1)
        // }
  return (
    <div className='w-full h-full flex flex-col  gap-1'>
      
            
            {/* <h1 className='text-right mr-3 font-semibold text-[#0042F5] text-lg'>לשבוע הקרוב</h1>
            <div className='w-full flex items-center justify-center border border-[#A7A7A7] rounded-lg my-1 p-2 '> */}
            {/* <div className="flex-grow overflow-auto bg-white p-2 rounded-lg shadow"> */}
                {/* <img src={calendar} alt="" />
                <span className='ml-4'>השבוע  אין קורסים שנרשמת</span>
            </div> */}
            {/* <h1 className='text-right mr-3 font-semibold text-[#0042F5] text-lg'>ימי עיון לשבוע הקרוב</h1> */}
            <h1 className='text-right mr-3 font-semibold text-[#0042F5] text-lg'>ימי עיון </h1>
            {/* <div className='w-full h-full grid grid-cols-2 my-2 gap-3 overflow-y-auto pb-28'> */}
            {/* <div className='w-full h-[65%] grid grid-cols-2 my-2 gap-3 overflow-y-auto '> */}
            <div className="flex-grow overflow-auto bg-white p-2 rounded-lg shadow">
            <div className='w-full  grid grid-cols-2 my-2 gap-3 overflow-y-auto '>
            {fetchData.map((course,index) => { return <Post course={course} Fromlink={corseId==course?.mySelfQualificationDto?.id?"popup":""} getTrainingDays={getTrainingDays}
            // setModalIsOpen={()=>setModalIsOpen(index)}
            key={index}/> })}
                {/* <Post></Post>    
                <Post></Post>    
                <Post></Post>    
                <Post></Post>    
                <Post></Post>   
                <RegistrationCourse/> */}
                {/* <PaymentModal/> */}
                {/* <SignatureModal/>  */}
                {/* {modalIsOpen!=-1 &&<RegistrationCourse course={fetchData[modalIsOpen]}onClose={handleColse}/>}  */}
            </div>
            </div>
        {/* <NavBar selected={selected} setSelected={setSelected}></NavBar> */}
       
    </div>
  )
}

export default Home