import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../../Modall';
import { urlToFile } from '../../../util/files';
const GET_URL = 'admin/qualification/instructor'


const ModalNewGuides = ({ showModal, children, handleColse, getDepartment, row = "" }) => {
    // const [previewUrl, setPreviewUrl] = useState('');
    const [formData, setFormData] = useState(
        // row ?
        //     {
        //         frame_manager: row?.frame_manager,
        //         training_manager: row?.training_manager,
        //         training_imgSignature: null,
        //         frame_imgSignature: null,
        //         training_imgSignature_url: row?.training_imgSignature ? `https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.training_imgSignature}` : "",
        //         frame_imgSignature_url: row?.frame_imgSignature ? `https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.frame_imgSignature}` : "",
        //     } :
        {
            frame_manager: "",
            training_manager: '',
            frame_imgSignature: null,
            frame_imgSignature_url: "",
            training_imgSignature: null,
            training_imgSignature_url: "",
        });
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    useEffect(() => { console.log(formData) }, [formData])
    const validate = (id) => {
        const newErrors = {};
        // if (!formData.frame_manager) newErrors.frame_manager = 'שדה חובה';
        if (!formData.training_manager &&!id) newErrors.training_manager = 'שדה חובה';
        // if (!formData.frame_imgSignature) newErrors.frame_imgSignature = 'שדה חובה';
        if (!formData.training_imgSignature &&!id) newErrors.training_imgSignature = 'שדה חובה';


        return newErrors;
    };

    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            // console.log(formData)
            addNewGuides()
        }
    };




    const handleFileChange = (event) => {
        const { name } = event.target;
        const file = event.target.files[0];

        if (file) {
            // עדכון השדה המתאים בהתאם לשם
            setFormData((prev) => ({
                ...prev,
                [name]: file
            }));

            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData((prev) => ({
                    ...prev,
                    [`${name}_url`]: e.target.result
                }));
                // console.log(`${name}_url:`, e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const fetchFile = async () => {
            if (row) {
                let file1 = null
                let file2 = null
                if (row?.training_imgSignature)
                    file1 = await urlToFile(`https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.training_imgSignature}`, row?.training_imgSignature.split('/').pop())
                if (row?.frame_imgSignature)
                    file2 = await urlToFile(`https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.frame_imgSignature}`, row?.frame_imgSignature.split('/').pop())
                setFormData((prev) => ({
                    ...prev,
                    training_imgSignature: file1,
                    frame_imgSignature: file2,

                }
                ))
            }

        };

        fetchFile();
    }, [])






    const handleEditClass = async (id) => {
        const validationErrors = validate(id);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            let formData1 = new FormData();
            formData1.append('instructorDetails', JSON.stringify({
                "frame_manager": formData.frame_manager,
                "training_manager": formData.training_manager,
            }));
            console.log(formData1)
            formData1.append('training_img_Signature ', formData.training_imgSignature);
            formData1.append('frame_img_Signature', formData.frame_imgSignature);
            const response = await axios.put(`${GET_URL}/${id}`, formData1
                ,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            if (response.status == 200) {
                getDepartment()
                handleColse()
                console.log("Edit")
            }
        }
    }
    // const urlToFile = async (imageUrl, fileName) => {
    //     const response = await axios.get(imageUrl, { responseType: 'blob' })
    //     const blob = await response.blob();
    //     return new File([blob], fileName,  { type: "image/jpeg" });
    // };

    const addNewGuides = async () => {

        let formData1 = new FormData();
        formData1.append('instructorDetails', JSON.stringify({
            "frame_manager": formData.frame_manager,
            "training_manager": formData.training_manager,
        }));
        console.log(formData1)
        formData1.append('training_img_Signature ', formData.training_imgSignature);
        formData1.append('frame_img_Signature', formData.frame_imgSignature);

        const response = await axios.post(GET_URL, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })



        // const response = await axios.post(GET_URL,{
        //     [{'instructorDetails':{ 
        //     "name": formData.fullname,
        //     "identity": formData.identity,
        //     "phone": formData.phone}},
        //     "img_Signature": file]
        // }     
        // , {
        //     headers: { 'Content-Type': 'multipart/form-data' }
        // })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            if (row) {
                try {


                    let file1 = null;
                    let file2 = null;

                    if (row?.training_imgSignature)
                        file1 = await urlToFile(
                            `https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.training_imgSignature}`,
                            // response?.data?.imgFacePicture
                            row?.training_imgSignature?.split('/').pop()
                        );

                    if (row?.frame_imgSignature)
                        file2 = await urlToFile(
                            `https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.frame_imgSignature}`,
                            row?.frame_imgSignature?.split('/').pop()
                        );

                    setFormData({
                        frame_manager: row?.frame_manager,
                        training_manager: row?.training_manager,
                        training_imgSignature: file1 || null,
                        frame_imgSignature: file2 || null,
                        training_imgSignature_url: row?.training_imgSignature ? `https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.training_imgSignature}` : "",
                        frame_imgSignature_url: row?.frame_imgSignature ? `https://archivebureau.s3.eu-west-3.amazonaws.com/${row?.frame_imgSignature}` : "",

                    });


                }
                 catch (err) {
                console.error("שגיאה בקבלת הנתונים:", err);
            }
        }
    };

    fetchData();
}, []);




return (
    <Modall showModal={showModal}>
        <div className=' w-full divide-y-2  divide-slate-500'>
            <div className='w-full bg-[#0d406b] flex justify-end'>
                <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                    handleColse()
                }} >x</button>
            </div>


            <form className='w-full p-5 flex flex-col  text-center text-sm'>
                <div className='w-full pt-2 flex justify-end'>

                    <input
                        className=' w-1/2 border  text-right'
                        type="text"
                        id="frame_manager"
                        name="frame_manager"
                        value={formData.frame_manager}
                        onChange={handleChange}
                    />
                    {/* <label htmlFor="frame_manager " className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מנהל מסגרת</p></label> */}
                    <label htmlFor="frame_manager " className='w-[30%]'><p className='ml-2'>מנהל מסגרת</p></label>

                </div>
                {errors.frame_manager && <span className="error">{errors.frame_manager}</span>}

                <div className='w-full pt-2 flex justify-end'>

                    <input
                        className='border w-1/2 text-right'
                        type="text"
                        id="training_manager"
                        name="training_manager"
                        value={formData.training_manager}
                        onChange={handleChange}
                    />
                    <label htmlFor="training_manager" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מנהל הדרכה</p></label>

                </div>
                {errors.training_manager && <span className="error">{errors.training_manager}</span>}


                <div className="w-full pt-2 flex justify-end">

                    <input
                        className='border w-1/2 h-fit '
                        type="file"
                        accept='image/*'
                        id="frame_imgSignature"
                        name="frame_imgSignature"
                        // value={formData.urlimage}
                        onChange={handleFileChange}
                    />
                    <label htmlFor="frame_imgSignature" className='w-[30%]'><p className='ml-2'>חתימה של מנהל המסגרת (העלאת הקובץ)</p></label>

                </div>



                {/* <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="text"
                            id="urlimage"
                            name="urlimage"
                            value={formData.urlimage}
                        // disabled
                        />
                        <label htmlFor="urlimage" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>חתימה (הקישור)</p></label>

                    </div> */}
                {/* {errors.urlimage && <span className="error">{errors.urlimage}</span>} */}
                {errors.file && <span className="error">{errors.file}</span>}
                {<img className='w-[160px] h-[160px]  object-cover m-4 mb-0 flex self-end' src={formData?.frame_imgSignature_url || "https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png"} />}


                <div className="w-full pt-2 flex justify-end">

                    <input
                        className='border w-1/2 h-fit '
                        type="file"
                        accept='image/*'
                        id="training_imgSignature"
                        name="training_imgSignature"
                        // value={formData.urlimage}
                        onChange={handleFileChange}
                    />
                    <label htmlFor="training_imgSignature" className='w-[30%] '><p className='ml-2'><span className='text-red-600'>*</span>חתימה של מנהל ההדרכה (העלאת הקובץ)</p></label>

                </div>



                {/* <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="text"
                            id="urlimage"
                            name="urlimage"
                            value={formData.urlimage}
                        // disabled
                        />
                        <label htmlFor="urlimage" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>חתימה (הקישור)</p></label>

                    </div> */}
                {/* {errors.urlimage && <span className="error">{errors.urlimage}</span>} */}
                {errors.training_imgSignature && <span className="error">{errors.training_imgSignature}</span>}
                {<img className='w-40 h-40 object-cover m-4 mb-0 flex self-end' src={formData?.training_imgSignature_url || "https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png"} />}




            </form>
            <div className='w-full p-2   flex justify-end'>
                <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {

                    row ? handleEditClass(row.id) : handleSubmit()

                    // handleSubmit()


                }}>שמור</button>
                <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                    // reset()
                    // setShowModal(false)
                    handleColse()
                }}>בטל</button>
            </div>
        </div>

    </Modall>
)

}

export default ModalNewGuides