import React, { useState }  from 'react'
import { BsPlusCircle } from "react-icons/bs";
import { IconContext } from "react-icons";

const TrainingDay = ({ course, onOpenModal}) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure 2 digits for month
        const day = ('0' + date.getDate()).slice(-2); // Ensure 2 digits for day
        return `${day}/${month}/${year}`;
    };
    return (
        <div className='text-right  flex flex-col border rounded-md m-2 p-1 ' style={{ direction: "rtl" }}>
            <div className='text-base font-bold underline mb-1'>פרטי יום הכשירות
            </div>
            {/* <div className='text-sm font-bold mb-1'>ציוד מגן אישי - יהודה נייקרוג - באר שבע - צהריים - 1041657 - 1041657 */}
            <div className='text-sm font-bold mb-1'>{course?.mySelfQualificationDto?.name}
            </div>
            <div className='text-xs font-normal mb-1'>{course?.mySelfQualificationDto?.description}
            </div>
            <div className='grid text-xs grid-cols-3'>
                <div className='grid'>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm  font-bold underline'>תאריך התחלה:</div>
                        <div>{course?.mySelfQualificationDto?.startDate?formatDate(course?.mySelfQualificationDto?.startDate):""}</div>
                    </div>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm  font-bold underline'>מספר מקומות שנותרו:</div>
                        <div>{course?.participantsAvailable}</div>
                    </div>
                    <div className='flex items-center'>
                        <div className='ml-2  text-sm font-bold underline'>אשכול:</div>
                        <div>{course?.mySelfQualificationDto?.cluster?.name}</div>
                    </div>

                </div>
                <div className='grid '>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm font-bold underline'>תאריך סיום:</div>
                        <div>{course?.dateEnd?formatDate(course?.dateEnd):""}</div>
                    </div>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm font-bold underline'>מיקום:</div>
                        <div>{course?.mySelfQualificationDto?.place}</div>
                    </div>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm font-bold underline'>קוד נושא:</div>
                        <div>{course?.product?.code}</div>
                        
                    </div>
                </div>
                <div className='grid'>
                <div className='flex items-center'>
                        <div className='ml-2 text-sm font-bold underline'>סה"כ ימי כשירות:</div>
                        <div>{course?.daysWork}</div>
                    </div>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm font-bold underline'>עלות:</div>
                        <div>{course?.product?.price}</div>
                    </div>
                    <div className='flex items-center'>
                        <div className='ml-2 text-sm font-bold underline'>פרטי מדריך:</div>
                        <div>{course?.mySelfQualificationDto?.instructor?.name}</div>
                    </div>
                </div>

            </div>
            <IconContext.Provider value={{ size:"29px", className:"ml-2" }}>
            <button className='bg-[#1e8e2b] rounded-md text-white p-2 w-fit flex self-end items-center text-sm font-medium' onClick={onOpenModal}> <BsPlusCircle/> להרשמה ופרטים נוספים</button>
            </IconContext.Provider>

        </div>

    )
}

export default TrainingDay