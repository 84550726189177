import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { MdAssignment } from "react-icons/md"
import axios from 'axios'
import Card from '../Card'
import ModalEdit from '../ModalEdit';

import Modal from '../Modal';
import ModalNewPaying from './ModalNewPaying';
import Fillter from '../Fillter';
import Paying_customersFilter from './Paying_customersFilter';
import Paying_customers_More_options from './Paying_customers_More_options/Paying_customers_More_options';
import { HeaderTable } from '../HeaderTable';
const GET_PAYING_CUSTOMERS_URLD = 'admin/setting/paying_customer'
// const GET_PAYING_CUSTOMERS_URL = 'admin/setting/paying_customers_page?page=0&size=20&sortBy=name'
const GET_PAYING_CUSTOMERS_URL = 'admin/setting/paying_customers_page'
 const GET_Filter=`admin/setting/paying_customers_names_identity`

const Paying_customers = () => {
   
    
    const [datafiler, setDatafiler] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [listClass, setlistClass] = useState([])
    const [loading, setloading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFillter, setDataFillter] = useState({
        'name': '',
        'identity': ''
    })
    const handleColse = () => {
        setModalIsOpen("")
    }


    const getFilter = async () => {

        await axios.get(GET_Filter, {
        })
            .then(response => {
                setDatafiler(response?.data)

            })

    }

    const handleDetelePaying_customer = async () => {

        await axios.delete(`${GET_PAYING_CUSTOMERS_URLD}/${id.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getPaying_customers(currentPage-1)
                getFilter()
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }


    const handlePageChange = (page) => {
        // fetchUsers(page);
        getPaying_customers(page);
        setCurrentPage(page);
      };


      const handlePerRowsChange = async (newPerPage) => {
        // setloading(true);
    
    
        // setData(response.data.items);
        setPerPage(newPerPage);
        // setTotalRows(response.data.totalCount);
        // setloading(false);
    }


    
    const getPaying_customers = async (page) => {
        // setloading(true);
        // await axios.get(`${GET_PAYING_CUSTOMERS_URL}s`).then(response => {
            await axios.get(GET_PAYING_CUSTOMERS_URL,{
                params:{"identity":dataFillter.identity
                    ,"name":dataFillter.name
                    ,"page":page
                    ,"size":perPage,
                    "sortBy":"name"
                }})
        .then(response => {
            setlistClass(response?.data?.payingDto)
            setTotalRows(response.data?.totalElements);
        })
        setloading(false)
        // console.log(listClass)
    }

    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden'; // Disable body scrolling
          } else {
            document.body.style.overflow = 'auto'; // Enable body scrolling
          }
      
          return () => {
            document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
          };
        // setHide(modalIsOpen)
    }, [modalIsOpen])

    useEffect(() => {
        getPaying_customers(currentPage-1)
    }, [currentPage,perPage,dataFillter])

    useEffect(()=>{
        getFilter()
    },[])

    const columns = [
        {
            name: "פעולות",
            
            cell: row => <div className='w-full'>
                
                <button title="פעולות נוספות" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("MoreOptions")
                }}><MdAssignment /></button>




                
                <button className='  border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button>
                <button title="מחיקה" className='  border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("Delete")
                }}><IoTrashOutline /></button> </div>,
            style: {
                fontSize: '12px',
                justifyContent: 'center',

            },
            // grow: 1,


        },
        {
            name: "מייל ראשי למשלוח קבלות",
            // grow: 2,
            selector: (row) => row.primaryEmailReceipts,
            style: {
                fontSize: '12px',
                justifyContent: 'center',
                // width: '100px'
            },
            
        },


        {
            name: "מיקוד",
            // grow: 3,
            selector: (row) => row.postalCode,
            style: {
                fontSize: '12px',
                justifyContent: 'center',
                     // width: '100px'
            },
           

        },
        {
            name: "כתובת למשלוח דואר",
            // grow: 3,
            selector: (row) => row.shippingAddress,
            style: {
                fontSize: '12px',
                justifyContent: 'center',
                // width: '100px'
            },
          
        },

        {
            name: "ח.פ./ע.מ./ת.ז.",
            cell: row => row.identity,

            style: {
                fontSize: '12px',
                justifyContent: 'center',

            },
            // grow: 3,


        },

        {
            name: "שם לקוח משלם",
            cell: row => row.name,

            style: {
                fontSize: '12px',
                justifyContent: 'center',

            },
            // grow: 2,


        },



    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }
    return (
        <div className='w-full p-3'>
            {/* <Card active={"מחלקות"} nameTab={"שם המחלקה"} getDepartment={getPaying_customers}></Card> */}
            <Paying_customersFilter  datafiler={datafiler} setDataFillterout={setDataFillter} />
            <HeaderTable active={"לקוחות משלמים"} setModalIsOpen={setModalIsOpen} />

            <DataTable columns={columns} data={listClass} pagination 
            customStyles={customStyles} 
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            progressPending={loading} className='border' >
            
            </DataTable>

            
        
            {modalIsOpen == "New" && <ModalNewPaying showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={()=>getPaying_customers(currentPage-1)} getFilter={getFilter}>
            </ModalNewPaying>}
            {modalIsOpen == "Edit" && <ModalNewPaying showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={()=>getPaying_customers(currentPage-1)} row={id} getFilter={getFilter} >
            </ModalNewPaying>}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetelePaying_customer}></ModalEdit>}
            {modalIsOpen == "MoreOptions" && <Paying_customers_More_options  showModal={modalIsOpen == "MoreOptions"} handleColse={handleColse} row={id} ></Paying_customers_More_options>}
        </div>
    )
}

export default Paying_customers