import React, { useEffect, useState } from 'react'
import CourseStatusCard from '../copmponents/CourseStatusCard';
import axios from 'axios';
import { convertToIsraelTime } from '../../util/formatsForDate';
const GET_URLDELETE = 'dashboard/member/detach_qualification_day'

const MyCourses = () => {
  const [courses, setCourses] = useState([]);
  const GET_URL = "dashboard/member/my_qualification_day";

  const handleDetele = async (id) => {
try {
    await axios.get(`${GET_URLDELETE}/${id}`).then(response => {
        if (response.status == 200) {
            // handleColse()
            getData()
            console.log("ok")
        }
        else {
            console.log("error")
        }
    })
} catch (error) {
  console.error("Error fetching courses:", error);
}
}
  const getData = async () => {
    try {
      const response = await axios.get(GET_URL);
      if (response.status === 200) {
        setCourses(response?.data);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {!courses.length &&<h2>-לא קיימים קורסים- </h2>}
      {courses.map((course, index) => (
        // console.log(course)
        <CourseStatusCard
          key={index}
          ifcancel={course?.isCanDetach}
          courseId={course?.mySelfQualificationDto?.id}
          courseName={course?.mySelfQualificationDto?.name}
          date={convertToIsraelTime(course?.mySelfQualificationDto?.startDate)}
          status={course?.mySelfQualificationDto?.statusQualification?.statusDescription}
          certificateUrl={course?.reportCertificateNumberDto?.linkUrl} // להוסיף URL של תעודה אם קיים
          handleDetele={handleDetele}
        />
      ))}
    </div>
  );
};

export default MyCourses;