import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import Select from "react-select";

const ModleNewRegistersMemberQualification = ({ handleColse, row, getDataTable }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [error, setError] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const GET_URL = "admin/qualification/register_qualification_display_pays";

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(GET_URL);
        if (response.status === 200) {
          setCompanies(response.data);
        }
      } catch (error) {
        console.error("שגיאה בטעינת החברות:", error);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!selectedCompany) {
        setEmployees([]);
        return;
      }
      try {
        const response = await axios.get(
          `admin/qualification/register_qualification_display_pays_members/${selectedCompany}`
        );
        if (response.status === 200) {
          setSelectedEmployees([]); // ניקוי בחירה קודמת
          setEmployees(response.data);
        }
      } catch (error) {
        console.error("שגיאה בטעינת עובדים:", error);
      }
    };
    fetchEmployees();
  }, [selectedCompany]);

  const handleEmployeeSelection = useCallback((employeeId) => {
    setSelectedEmployees((prev) =>
      prev.includes(employeeId) ? prev.filter((id) => id !== employeeId) : [...prev, employeeId]
    );
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedEmployees((prev) =>
      prev.length === employees.length ? [] : employees.map((emp) => emp.id)
    );
  }, [employees]);

  const handleSubmit = useCallback(async () => {
    try {
      const response = await axios.post(
        `admin/qualification/register_qualification_display_pays_members_group_join/${selectedCompany}/${row}`,
        selectedEmployees
      );
      if (response.status === 200) {
        getDataTable();
        handleColse();
      }
    } catch (error) {
      console.error("שגיאה בשליחת הנתונים:", error);
      setError("אירעה שגיאה בשליחת הנתונים.");
    }
  }, [selectedCompany, row, selectedEmployees, getDataTable, handleColse]);

  // שימוש ב- useMemo ליצירת האופציות של ה- Select
  const companyOptions = useMemo(
    () =>
      companies.map((company) => ({
        value: company.id,
        label: company.display,
      })),
    [companies]
  );

  return (
    <div className="absolute top-0 h-screen overflow-y-auto left-0 flex flex-col items-center pt-5 w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50">
      <div className="bg-white w-1/2 rounded-lg divide-y-2 divide-slate-500 border-0 border-emerald-50">
        <div className="w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2">
          <button className="p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200" onClick={handleColse}>
            ×
          </button>
        </div>

        <form className="w-full p-4 flex flex-col text-center text-sm" dir="rtl">
          <div className="p-4 bg-white shadow-lg rounded-lg w-full max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">לקוח משלם</h2>
            <Select
              options={companyOptions}
              placeholder="-- בחר לקוח משלם --"
              onChange={(selectedOption) => setSelectedCompany(selectedOption ? selectedOption.value : null)}
              isSearchable
              isClearable
              className="w-full border border-gray-300 rounded-lg mb-4"
            />
            {employees.length > 0 && (
              <div>
                <h3 className="text-lg font-semibold mb-2">בחר חבר לשכה</h3>
                <button
                  type="button"
                  className="bg-blue-500 text-white px-3 py-1 rounded mb-2 w-fit"
                  onClick={handleSelectAll}
                >
                  {selectedEmployees.length === employees.length ? "בטל בחירה של כולם" : "בחר את כולם"}
                </button>
                <div className="border p-2 rounded-lg max-h-60 overflow-y-auto">
                  {employees.map((employee) => (
                    <label key={employee.id} className="flex items-center gap-2 p-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={selectedEmployees.includes(employee.id)}
                        onChange={() => handleEmployeeSelection(employee.id)}
                      />
                      {`${employee.firstName} ${employee.lastName}`}
                    </label>
                  ))}
                </div>
                {error && <span className="text-red-500 text-center">{`שגיאה: ${error}`}</span>}
              </div>
            )}
          </div>
        </form>
        <div className="w-full p-2 flex justify-end">
          <button
            type="button"
            disabled={!selectedEmployees.length}
            className="border bg-green-500 hover:bg-green-300 p-1 px-3"
            onClick={handleSubmit}
          >
            שמור
          </button>
          <button className="border bg-slate-700 hover:bg-slate-500 p-1 px-3" onClick={handleColse}>
            בטל
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModleNewRegistersMemberQualification;
