import React, { useEffect, useState, useRef } from 'react'
import Modall from '../Modall';
import { FaUpload } from 'react-icons/fa';

import Select from "react-select"
import axios from 'axios'
import Input from '../ui/Input';
import { isEmailValid, validateIsraeliID } from '../../util/validation';
import { convertDateFormatInputDate } from '../../util/formatsForDate';
// const GET_URL = 'admin/member/init_filter_member'
const GET_URL = 'admin/member/init_member_create'
const GET_CREACT = 'admin/member/create_member'
const GET_EDIT = 'admin/member/edit_member'
const ModalNewMembr = ({ getDataFILTER, getDepartment, handleColse, row = "" }) => {
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // חודש בפורמט דו-ספרתי
        const day = String(today.getDate()).padStart(2, '0');         // יום בפורמט דו-ספרתי
        return `${year}-${month}-${day}`;
    };

    // מאתחלים את ה-state עם תאריך של היום
    const [selectedDate, setSelectedDate] = useState(getTodayDate);


    const arrtel = ["קידומת", "050", "051", "052", "053", "054", "055", "057", "058", "059"]
    const arrJobsPlace = ["עצמאי", "שכיר במוסד ממשלתי", "שכיר ברשות מקומית", "שכיר צה\"ל", "שכיר בחברה לייעוץ בטיחות", "שכיר בקיבוץ/מקום עבודה שיתופי", "שכיר במקום עבודה בפרטי", "אחר"]
    const fileInputRef = useRef(null);
    const imgInputRef = useRef(null);
    const signInputRef = useRef(null);
    // const [statusList, setstatusesList] = useState("");
    const [payList, setpayList] = useState("");
    const [citysList, setcitysList] = useState([]);
    const [streetList, setstreetList] = useState([]);

    console.log(row)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState(row ? {} :
        {
            dateRegister: selectedDate,
            number: payList?.number,
            status: 'נפתח',
            firstName: '',
            lastName: '',
            idNumber: '',
            dateBorn: "",
            job: '',
            payingCustomerId: '',
            street: '',
            houseNumber: null,
            apartmentNumber: null,
            city: '',
            email: '',
            additionalPhone: '',
            nsNumber: null,
            gender: null,
            phonePrefix: '',
            phone: '',
            extraPhonePrefix: '',
            extraPhone: '',
        }

    )

    // const urlToFile = async (imageUrl, fileName) => {
    //     const response = await fetch(imageUrl);
    //     const blob = await response.blob();
    //     return new File([blob], fileName,  { type: "image/jpeg" });
    // };
    const urlToFile = async (url, filename) => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error("Failed to fetch file from URL");
            const blob = await response.blob();
            const fileExtension = filename?.split('.').pop()?.toLowerCase();

            const extensionToMime = {
              jpg: "image/jpeg",
              jpeg: "image/jpeg",
              png: "image/png",
              pdf: "application/pdf",
              doc: "application/msword",
              docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            };
          
            const mimeType = extensionToMime[fileExtension] || blob.type || "application/octet-stream";
          
            return new File([blob], filename, { type: mimeType });
        } catch (error) {
            console.error("urlToFile error:", error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          if (row) {
            try {
              const response = await axios.get(`admin/member/get_member/${row?.id}`);
              if (response.status === 200) {
              console.log(response?.data)
                let file1 = null;
                let file2 = null;
                let file3 = null;
      
                if (response?.data?.imgFacePicture)
                  file1 = await urlToFile(
                    `https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.imgFacePicture}`,
                    // response?.data?.imgFacePicture
                    response?.data?.imgFacePicture?.split('/').pop()
                  );
      
                if (response?.data?.imgSignature)
                  file2 = await urlToFile(
                    `https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.imgSignature}`,
                    response?.data?.imgSignature?.split('/').pop()
                  );
                if (response?.data?.imgCertificate)
                  file3 = await urlToFile(
                    `https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.imgCertificate}`,
                    response?.data?.imgCertificate.split('/').pop()
                  )
                //   ;imgCertificate
                //   : 
                //   "members/3473/certificate_3473.pdf
      
                setFormData({
                  dateRegister: convertDateFormatInputDate(response?.data?.dateRegister),
                  number: response?.data?.number,
                  status: response?.data?.status?.statusDescription,
                  firstName: response?.data?.firstName,
                  lastName: response?.data?.lastName,
                  idNumber: response?.data?.idNumber,
                  dateBorn: convertDateFormatInputDate(response?.data?.dateBorn),
                  job: response?.data?.job,
                  payingCustomerId: response?.data?.scrollPayingDto,
                  street: response?.data?.street,
                  houseNumber: response?.data?.houseNumber,
                  apartmentNumber: response?.data?.apartmentNumber,
                  city: response?.data?.city,
                  img_face_picture:response?.data?.imgFacePicture? `https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.imgFacePicture}`:null,
                  img_signature: response?.data?.imgSignature?`https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.imgSignature}`:null,
                  
                  email: response?.data?.email,
                  additionalPhone: response?.data?.additionalPhone,
                  nsNumber: response?.data?.nsnumber,
                  phonePrefix: response?.data?.mainPhone?.slice(0, 3)||"",
                  phone: response?.data?.mainPhone?.slice(3)||"",
                  extraPhonePrefix: '',
                  extraPhone: '',
                });
      
                setDataFiles((prev) => ({
                  ...prev,
                  img_face_picture: file1,
                  img_signature: file2,
                  img_certificate: file3,
                //   img_certificate: file3,
                }));
              }
            } catch (err) {
              console.error("שגיאה בקבלת הנתונים:", err);
            }
          }
        };
      
        fetchData();
      }, []);
      

const [dataFiles, setDataFiles] = useState({
    img_face_picture: null,
    img_certificate: null,
    img_signature: null,
})

const handleTakePhotoClick = (ref) => {
    // Trigger the file input element to open the camera
    if (ref == "fileInputRef")

        fileInputRef.current.click();
    if (ref == "imgInputRef")
        imgInputRef.current.click();

    if (ref == "signInputRef")
        signInputRef.current.click()
};


const getPayList = async () => {

    await axios.get(GET_URL, {
    })
        .then(response => {
            setpayList(response?.data)
            if (!row?.number) {
                setFormData({ ...formData, number: response?.data?.number })

            }
        })


}

const getCity = async () => {
    // await axios.get('https://data.gov.il/api/3/action/datastore_search', {

    //         params: { resource_id:"5c78e9fa-c2e2-4771-93ff-7f400a12f7ba",q:"", limit:"100" },
    //         responseType: "json",
    //       }).then(response => {
    //     if (response.status == 200) {
    //         console.log("response?.data")
    //     }
    // })
    await axios.get('admin/setting/all_city', {
    }).then(response => {
        if (response.status == 200) {
            setcitysList(response?.data)
        }
    })
}
const getStreets = async () => {

    if (formData.city) {
        await axios.get(`admin/setting/all_street/${formData?.city}`, {
        }).then(response => {
            if (response.status == 200) {
                setstreetList(response?.data)
            }
        })
    }
    else { setstreetList([]) }
}

useEffect(() => {
    getPayList()
    getCity()
}, [])
useEffect(() => {
    getStreets()
    if (!row?.number) {
        formData.street = ""
    }
}, [formData?.city])


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '20px',
        height: '20px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '20px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '20px',
    }),
};





const validateField = (name, value) => {
    let error = "";

    switch (name) {
        case "firstName":
            if (!value.trim()) {
                error = "שם לקוח הינו שדה חובה.";
            }
            break;
        case "lastName":
            if (!value.trim()) {
                error = "הינו שדה חובה."
            }

            break;
        case "nsNumber":
            if (!value.trim()) {
                error = "הינו שדה חובה."
            }

            break;
        case "idNumber":
            if (!value.trim()) {
                error = " הינו שדה חובה.";
            }
            if (value.trim() && !/^\d+$/.test(value)) {
                error = "מספר טלפון חייב להיות מספרים בלבד.";
            }
            else
                if (value.trim() && !validateIsraeliID(value)) {
                    error = " ת.ז שגוי";
                }
            break;
        case "dateRegister":
            if (!value.trim()) {
                error = " הינו שדה חובה.";
            }

            break;



        default:
            break;
    }

    return error;
};


const handleChangeSelect = (selectedOption, name) => {
    // console.log(selectedOption)
    setFormData({
        ...formData,
        [name]: selectedOption?.value
        ,
    })
}


const handleDateChange = (date, name) => {
    setFormData({
        ...formData,
        personalDetails: {
            ...formData.personalDetails,
            [name]: date,
        }
    });
};





const handleFileChange = (event) => {
    const { name } = event?.target
    const file1 = event.target.files[0];

    // setFormData({
    //     ...formData,
    //     [name]: event.target.value
    // })
    if (file1) {


        setDataFiles({ ...dataFiles, [name]: file1 })
        const reader = new FileReader();
        reader.onload = (e) => {
            // setPreviewUrl(e.target.result);
            setFormData({ ...formData, [name]: e.target.result });

        };

        reader.readAsDataURL(file1);


    }











}

const validate = () => {
    const newErrors = {};

    if (!formData?.firstName?.trim()) {
        newErrors.firstName = "הינו שדה חובה.";
    }
    if (!formData?.lastName?.trim()) {
        newErrors.lastName = "הינו שדה חובה."
    }
    if (!formData?.idNumber?.trim()) {
        newErrors.idNumber = "הינו שדה חובה.";
    }
    if (!formData?.dateBorn?.trim()) {
        newErrors.dateBorn = "הינו שדה חובה.";
    }
    if (!formData?.email?.trim()) {
        newErrors.email = "הינו שדה חובה.";
    }
    // if (!formData?.payingCustomerId?.display?.trim()) {
    //     newErrors.payingCustomerId = "הינו שדה חובה.";
    // }
    if (!formData?.nsNumber?.trim()) {
        newErrors.nsNumber = "הינו שדה חובה.";
    }





    if (!formData?.dateRegister?.trim()) {
        newErrors.dateRegister = "הינו שדה חובה.";
    }
    if (!formData?.number?.trim()) {
        newErrors.number = "הינו שדה חובה.";
    }
    if (!formData?.job?.trim()) {
        newErrors.job = "הינו שדה חובה.";
    }
    if (!formData?.street?.trim()) {
        newErrors.street = "הינו שדה חובה.";
    }
    if (!formData?.city?.trim()) {
        newErrors.city = "הינו שדה חובה.";
    }



    setErrors(newErrors);
    console.log(newErrors)
    
    return Object.keys(newErrors).length == 0;
};

// פונקציה לבדיקת ולידציה לשדות
const validate1 = () => {
    // let valid = true;
    const newErrors = {};
    for (const item in formData) {
        console.log(formData[item])
        if (!formData[item]) { newErrors[item] = 'שדה זה הוא חובה' }

    }
    // בדיקת תקינות אימייל
    // if (!formData.email) {
    //     newErrors.email = 'שדה האימייל הוא חובה';
    // valid = false;
    // } else 
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'אימייל לא תקין';
        // valid = false;
    }



    // בדיקת מספר טלפון
    if (!formData.phone) {
        newErrors.phone = 'שדה מספר הטלפון הוא חובה';
        // valid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) { // בדיקה אם המספר מכיל 10 ספרות
        newErrors.phone = 'מספר הטלפון צריך להיות בן 10 ספרות';
        // valid = false;
    }

    setErrors(newErrors);
    return newErrors ? false : true;
};



const addNewMember = async (id) => {

    try {
        const formData1 = new FormData();
        formData1.append(
          'memberDetails',
          JSON.stringify({
            dateRegister: formData?.dateRegister,
            number: formData?.number,
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            idNumber: formData?.idNumber,
            dateBorn: formData?.dateBorn,
            job: formData?.job,
            street: formData?.street,
            apartmentNumber: Number(formData?.apartmentNumber),
            houseNumber: Number(formData?.houseNumber),
            city: formData?.city,
            email: formData?.email,
            mainPhone: `${formData?.phonePrefix}${formData?.phone}`,
            additionalPhone: formData?.additionalPhone,
            nsNumber: formData?.nsNumber,
            status: formData?.status,
            payingCustomerId: Number(formData?.payingCustomerId?.id),
          })
        );
    
        formData1.append('img_face_picture', dataFiles?.img_face_picture);
        formData1.append('img_certificate', dataFiles?.img_certificate);
        formData1.append('img_signature', dataFiles?.img_signature);
    
        let response;
    
        if (!id) {
          response = await axios.post(GET_CREACT, formData1, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } else {
          response = await axios.put(`${GET_EDIT}/${id}`, formData1, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }
    
        if (response?.status === 200) {
          getDepartment();
          getDataFILTER();
          alert('הטופס נשלח בהצלחה!');
          handleColse();
        } else {
          alert('אירעה שגיאה בעת שליחת הטופס');
        }
      } catch (error) {
        console.error('שגיאה בשליחת הטופס:', error);
        // אם השרת מחזיר הודעה ברורה מהשגיאה
        const serverMessage =
          error?.response?.data?.message || 'שגיאה בלתי צפויה התרחשה';
        alert(`שגיאה בשליחת הטופס: ${serverMessage}`);
      }
    };
const handleSubmit = (e) => {
    // addNewMember()
    // e.preventDefault();
    if (validate()) {
        addNewMember("")
        // console.log('טופס נשלח בהצלחה:', formData);
        // כאן אפשר לשלוח את הנתונים לשרת או לבצע פעולות נוספות
    }
}
const handleEditMember = (id) => {
    // addNewMember()
    // e.preventDefault();
    if (validate()) {
        console.log("נכנס עבר את הבדיקות")
        addNewMember(id)
        // console.log('טופס נשלח בהצלחה:', formData);
        // כאן אפשר לשלוח את הנתונים לשרת או לבצע פעולות נוספות
    }
}
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
    const fieldError = validateField(name, value);
    setErrors({ ...errors, [name]: fieldError });
};
// useEffect(() => (console.log(formData)), [formData])
useEffect(() => (console.log(dataFiles)), [dataFiles])



return (
    <div className='fixed top-0 bottom-0 right-0 left-0 flex flex-col items-center py-4  min-h-screen  overflow-y-auto  w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50 '
        onClick={() => {
            // handleColse()
        }}>
        <div className='bg-white w-1/2 rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
            <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                    handleColse()
                }} >x</button>
            </div>
            <form className='max-md:w-[600px] m-[30px auto]  p-2 flex flex-col  ' style={{ direction: "rtl" }} >
                <div>
                    <h3 className='font-bold underline font-mono text-lg text-right' >פרטים אישיים</h3>
                    {/* <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>תאריך הרשמה</label>
                            <input
                                className='grid col-span-3'
                                type='date'
                                value={formData?.dateRegister}
                                // onChange={(date) => handleDateChange(date, 'dateRegister')}
                                id="dateRegister"
                                name="dateRegister"
                                onChange={handleChange}

                                dateFormat="dd/MM/yyyy"
                            />
                        </div> */}
                    <Input
                        req={true}
                        label='תאריך הרשמה'
                        type="date"
                        dateFormat="dd/MM/yyyy"
                        id="dateRegister"
                        name="dateRegister"
                        onChange={handleChange}
                        className='  grid col-span-3 border'
                        value={formData?.dateRegister}
                        error={errors?.dateRegister}
                    />



                    <Input
                        req={true}
                        label='מספר חבר'
                        type="text"
                        id="number"
                        name="number"

                        className='  grid col-span-5 border'
                        value={formData?.number}
                        onChange={handleChange} />


                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>סטטוס</label>

                        <Select className='  grid col-span-5 p-0 text-right'

                            options={payList?.statuses?.map((item) => { return { value: item?.statusDescription, label: item?.statusDescription } })}
                            placeholder="חפש..."
                            isClearable
                            isSearchable
                            styles={customStyles}
                            value={formData?.status ? { value: formData?.status, label: formData?.status } : null}
                            maxMenuHeight={100}
                            onChange={(e) => handleChangeSelect(e, 'status')}
                        ></Select>

                    </div>
                    <Input
                        label='שם פרטי'
                        req={true}
                        type="text"
                        id="firstName"
                        name="firstName"
                        className='grid col-span-5 border'
                        value={formData?.firstName}
                        error={errors?.firstName}
                        onChange={handleChange}
                    />


                    <Input
                        label='שם משפחה'
                        req={true}
                        className='grid col-span-5 border'
                        type="text"
                        id="lastName"
                        name="lastName"
                        error={errors?.lastName}
                        value={formData?.lastName}
                        onChange={handleChange}
                    />


                    <Input
                        label='ת.ז.'
                        req={true}
                        className='grid col-span-5 border'
                        type="number"
                        id="idNumber"
                        name="idNumber"
                        error={errors?.idNumber}
                        value={formData?.idNumber}
                        onChange={(e) => {
                            handleChange(e)
                            if (!validateIsraeliID(e.target.value)) { errors.idNumber = "sad" }
                            else {
                                errors.idNumber = ""
                            }


                        }}
                    />


                    <Input
                        label='תאריך לידה'
                        req={true}
                        className='grid col-span-3  border'

                        type="date"
                        // dateFormat="dd/MM/yyyy"
                        // placeholder="הכנס תאריך"
                        id="dateBorn"
                        name="dateBorn"
                        value={formData?.dateBorn}
                        error={errors?.dateBorn}
                        // selected={formData?.dateBorn}
                        // onChange={(date) => handleDateChange(date, 'dateBorn')}
                        onChange={handleChange}
                    />


                    {/* <Input
                            label='מקום עבודה'
                            req={true}
                            className='grid col-span-5  border'
                            type="text"
                            id="job"
                            name="job"
                            error={errors.job}
                            value={formData.job}
                            onChange={handleChange}
                        /> */}


                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>מקום עבודה</label>

                        <Select className='  grid col-span-5 p-0 text-right'

                            options={arrJobsPlace?.map((item) => { return { value: item, label: item } })}
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            styles={customStyles}
                            value={formData?.job ? { value: formData?.job, label: formData?.job } : null}
                            maxMenuHeight={100}
                            onChange={(e) => handleChangeSelect(e, 'job')}
                        ></Select>

                    </div>



                    {/* arrJobsPlace */}
                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3' >לקוח משלם</label>

                        <Select className='  grid col-span-5 p-0 text-right'
                            value={formData?.payingCustomerId ?
                                { value: formData.payingCustomerId.id, label: formData.payingCustomerId?.display } : null}
                            
                            options={payList?.scrollPaying?.map((item) => { return { value: item.id, label: item.display } })}
                            placeholder="חפש..."
                            isClearable
                            isSearchable
                            onChange={(e) => handleChangeSelect(e, 'payingCustomerId')}
                            styles={customStyles}
                            maxMenuHeight={100}
                        ></Select>
                    </div>
                </div>

                <div>
                    <h3 className='font-bold underline font-mono text-lg text-right'>כתובת</h3>
                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>עיר</label>

                        <Select className='  grid col-span-5 p-0 text-right'
                            options={citysList?.map((item) => { return { value: item, label: item } })}
                            placeholder="חפש..."
                            isClearable
                            isSearchable
                            styles={customStyles}
                            maxMenuHeight={100}
                            value={formData?.city ?
                                { value: formData.city, label: formData.city } : null}
                            onChange={(e) => {handleChangeSelect(e, 'city')
                                setFormData((prev)=>({...prev, street:""}))}
                            }
                        ></Select>
                        {/* <input
                                className='grid col-span-5  border'
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            /> */}
                    </div>
                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>רחוב</label>
                        <Select className='  grid col-span-5 p-0 text-right'
                            options={streetList?.map((item) => { return { value: item, label: item } })}
                            placeholder="חפש..."
                            isClearable
                            isSearchable
                            styles={customStyles}
                            maxMenuHeight={100}
                            // name="street"
                            value={formData?.street ?
                                { value: formData.street, label: formData.street } : null}
                            onChange={(e) => handleChangeSelect(e, 'street')}
                        // value={formData.street}
                        // onChange={handleChange}
                        ></Select>
                        {/* <input
                                className='grid col-span-5 border '
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                            /> */}
                    </div>
                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>מספר בית</label>
                        <input
                            className='grid col-span-5  border'
                            type="text"
                            name="houseNumber"
                            value={formData.houseNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>מספר דירה</label>
                        <Input
                            className='grid col-span-5  border'
                            type="text"
                            name="apartmentNumber"
                            value={formData.apartmentNumber}
                            onChange={handleChange}
                        />
                    </div>

                </div>

                <div>
                    <h3 className='font-bold underline font-mono text-lg text-right' >פרטי התקשרות</h3>

                    <Input
                        label='אימייל'
                        req={true}
                        className='grid col-span-5  border'
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        error={errors.email}
                        onChange={handleChange}
                    />

                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'   >טלפון ראשי</label>
                        <input
                            className='grid col-span-3  border'
                            type="number"
                            name="phone"
                            value={formData?.phone}
                            onChange={handleChange}
                        />
                        <select className='border grid col-span-2 ' name='phonePrefix'   value={formData?.phonePrefix} onChange={handleChange} >
                            {
                                arrtel.map((index) => {
                                    return (<option className='w-full'>
                                        {index}
                                    </option>)
                                })
                            }
                        </select>
                    </div>
                    <div className='grid grid-cols-10'>
                        <label className='grid col-span-3'>טלפון נוסף (אופציונלי)</label>
                        <input
                            className='grid col-span-3  border'
                            type="number"
                            name="extraPhone"
                            value={formData?.extraPhone}
                            onChange={handleChange}
                        />
                        <select className='border grid col-span-2 ' name='extraPhonePrefix' value={formData?.extraPhonePrefix} onChange={handleChange}>
                            {
                                arrtel.map((index) => {
                                    return (<option className='w-full'>
                                        {index}
                                    </option>)
                                })
                            }
                        </select>
                    </div>
                </div>

                <div>
                    <h3 className='font-bold underline font-mono text-lg text-right' >אישור כשירות</h3>

                    <Input
                        label='מספר נ.ש.'
                        req={true}
                        className='grid col-span-5  border'
                        type="number"
                        name="nsNumber"
                        id="nsNumber"
                        value={formData?.nsNumber}
                        error={errors?.nsNumber}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <h3 className='font-bold underline font-mono text-lg text-right' >קבצים מצורפים</h3>
                    <div className='border-2 border-black bg-[#ccc]  rounded-md py-2 px-1'>
                        <button type="button" className='flex text-[10px] w-fit place-self-center py-1 px-2 items-baseline bg-gray-400  border-black border my-2 rounded-sm text-white cursor-pointer' onClick={() => handleTakePhotoClick("imgInputRef")}>
                            <FaUpload />
                            <p className='mr-2'>העלה קובץ</p>
                        </button>
                        <input type="file" id="img_face_picture" name="img_face_picture"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            ref={imgInputRef}
                            accept="image/*"
                        />


                        {dataFiles.img_face_picture && (
                            <div className='flex flex-col items-center'>
                                <p>{dataFiles.img_face_picture.name}</p>
                                <p>KB {Math.round(dataFiles.img_face_picture.size / 1024)}</p>



                            </div>

                        )}




                        <div className="w-full pt-2 grid grid-cols-10">


                            <label htmlFor="urlimage" className='grid col-span-3'><p >תמונת פנים (התמונה תשמש לכרטיס חבר) <span className='text-red-600'>*</span></p></label>
                            <input
                                className='border grid col-span-5 text-right bg-slate-300'
                                type="text"
                                id="urlimage"
                                name="urlimage"
                                value={formData?.img_face_picture ? formData?.img_face_picture : ""}
                                disabled
                            />
                        </div>
                        {/* <div className='flex flex-col items-center'>
                                <img src={formData?.img_face_picture ? formData.img_face_picture : "https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png"} alt="Preview" className='border-2 border-black my-2' style={{
                                    width: '100px', height: '100px',
                                    // objectFit: 'cover'
                                }} />
                            </div> */}


                        <div className='flex flex-col items-center'>
                            {formData?.img_face_picture && typeof formData.img_face_picture === 'string' ? (
                                <img
                                    src={formData.img_face_picture}
                                    alt="Preview"
                                    className='border-2 border-black my-2'
                                    style={{ width: '100px', height: '100px' }}
                                />
                                //   ) : dataFiles.img_face_picture ? (
                                //     <img
                                //     //   src={URL.createObjectURL(dataFiles.img_face_picture)}
                                //       alt="Preview"
                                //       className='border-2 border-black my-2'
                                //       style={{ width: '100px', height: '100px' }}
                                //     />
                            ) : (
                                <img
                                    src="https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png"
                                    alt="Preview"
                                    className='border-2 border-black my-2'
                                    style={{ width: '100px', height: '100px' }}
                                />
                            )}
                        </div>

                    </div>



                    <div className='border-2 border-black bg-[#ccc]  rounded-md my-2 py-2 px-1'>
                        <button type="button" className='flex text-[10px] w-fit place-self-center py-1 px-2 items-baseline bg-gray-400  border-black border my-2 rounded-sm text-white cursor-pointer' onClick={() => handleTakePhotoClick("fileInputRef")}>
                            <FaUpload />
                            <p className='mr-2'>העלה קובץ</p>
                        </button>
                        <input type="file" id="img_certificate" name="img_certificate"
                            onChange={handleFileChange}
                            accept="application/pdf"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />

                        {dataFiles.img_certificate && (
                            <div className='flex justify-center w-full'>
                                <div className='flex flex-col bg-white w-1/2 px-4 '>
                                    <p>{dataFiles.img_certificate?.name}</p>
                                    <p>KB {Math.round(dataFiles.img_certificate?.size / 1024)}</p>
                                </div>
                            </div>

                        )}
                        <div className="w-full pt-2 grid grid-cols-10">


                            <label htmlFor="img_certificate" className='grid col-span-3'><p >אישור כשירות<span className='text-red-600'>*</span></p></label>
                            <input
                                className='border grid col-span-5 text-right bg-slate-300'
                                type="text"
                                id="img_certificate"
                                name="img_certificate"
                                value={dataFiles?.img_certificate?.name}
                                disabled
                            />
                        </div>

                    </div>





                    <div className='border-2 border-black bg-[#ccc]  rounded-md  py-2 px-1'>
                        <button type="button" className='flex text-[10px] w-fit place-self-center py-1 px-2 items-baseline bg-gray-400  border-black border my-2 rounded-sm text-white cursor-pointer' onClick={() => handleTakePhotoClick("signInputRef")}>
                            <FaUpload />
                            <p className='mr-2'>העלה קובץ</p>
                        </button>
                        <input type="file" id="img_signature" name="img_signature"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            accept="image/*"
                            ref={signInputRef}

                        />

                        {dataFiles?.img_signature && (
                            <div className='flex flex-col items-center'>
                                <p>{dataFiles?.img_signature.name}</p>
                                <p>KB {Math.round(dataFiles?.img_signature?.size / 1024)}</p>



                            </div>

                        )}
                        <div className="w-full pt-2 grid grid-cols-10">


                            <label htmlFor="urlimage" className='grid col-span-3'><p ><span className='text-red-600'>*</span>חתימה (הקישור)</p></label>
                            <input
                                className='border grid col-span-5 text-right bg-slate-300'
                                type="text"
                                id="urlimage"
                                name="urlimage"
                                value={formData?.img_signature ? formData?.img_signature : ""}
                                disabled
                            />
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={formData?.img_signature ? formData.img_signature : "https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png"} alt="Preview" className='border-2 border-black my-2' style={{
                                width: '100px', height: '100px',
                                // objectFit: 'cover'
                            }} />
                        </div>
                    </div>
                </div>



            </form>
            <div className='w-full p-2   flex justify-end'>
                <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                    // if (nameRole != "") {
                    // row ? handleEditRole(row.id) :
                    // addNewMember()
                    row ? handleEditMember(row.id) : handleSubmit()

                    // handleSubmit()
                    // }


                }}>שמור</button>
                <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                    // reset()
                    // setShowModal(false)
                    // addNewMember()
                    handleColse()
                }}>בטל</button>
            </div>
        </div>
    </div>
);
};


export default ModalNewMembr