export const urlToFile = async (url, filename) => {
    try {
        const response = await fetch(url, { mode: "cors" });
        if (!response.ok) throw new Error("Failed to fetch file from URL");
        const blob = await response.blob();
        const fileExtension = filename?.split('.').pop()?.toLowerCase();

        const extensionToMime = {
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
          pdf: "application/pdf",
          doc: "application/msword",
          docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };
      
        const mimeType = extensionToMime[fileExtension] || blob.type || "application/octet-stream";
      
        return new File([blob], filename, { type: mimeType });
    } catch (error) {
        console.error("urlToFile error:", error);
        return null;
    }
};