import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SuccessAnimation.css';

const SuccessAnimation = ({onNext,getTrainingDays,titlerror="הרשמה בוצעה בהצלחה"}) => {
  const navigate = useNavigate();

  useEffect(() => {
    // getTrainingDays()
    const timer = setTimeout(() => {

        onNext()
    //   navigate('/Mobile'); // מעביר לדף הבית
    }, 3000); // אחרי 3 שניות

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="success-container">
      <div className="checkmark-circle">
        <div className="background"></div>
        <div className="checkmark">✔</div>
      </div>
      <p className="success-text">{titlerror}</p>
    </div>
  );
};

export default SuccessAnimation