import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReDataTable from '../../ui/ReDataTable';
import { HeaderTable } from '../../HeaderTable';
import {formatDateIL} from "../../../util/formatsForDate"


const MembersTrainingdays = ({row}) => {
  const [fetchData, setFetchData] = useState([])
  const [loading, setloading] = useState(true)
  const [totalRows, setTotalRows] = useState(0);

    const GET_URL=`admin/member/get_qualification_days_member/${row?.id}`
    const getData = async (page,perPage) => {
        const response= await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            if (response.status=="200") 
             {

                const response1 = (response?.data?.listQualifications)
                const response2= (response?.data?.totalElements);
                return [response1, response2];
            }
            const response1 = []
            const response2= []
            return [response1, response2];
      };

const getDataTable = async (page,perPage) => {


        
    const [result1, result2] = await getData(page,perPage)
    setFetchData(result1);
    setTotalRows(result2); 
    setloading(false)
    console.log(fetchData)
  };

      
    const columns = [
       
       
        {
          name: "סטטוס",
          grow: 1,
          cell: (row) => row?.qualificationDay?.statusQualification?.statusDescription,
          style: {
            // fontSize: '16px',
            // justifyContent: 'center',
            // width: '100px'
          },
           
        },
        {
            name: "מיקום",
            grow: 2,
            cell: (row) => row?.qualificationDay?.place,
            style: {
              // fontSize: '16px',
              // justifyContent: 'center',
              // width: '100px'
            },
             
          },
        {
            name: "תאריך",
            grow: 1,
            selector: (row) => formatDateIL(row?.qualificationDay?.startDate),
            style: {
              // fontSize: '16px',
              // justifyContent: 'center',
              // width: '100px'
            },
             
          },
        {
            name: "שם יום הכשירות",
            grow: 2,
            cell: (row) => row?.qualificationDay?.name,
            style: {
              // fontSize: '16px',
              // justifyContent: 'center',
              // width: '100px'
            },
             
          },
        {
            name: "שם אשכול",
            grow: 2,
            cell: (row) => row?.qualificationDay?.cluster?.name,
            style: {
              // fontSize: '16px',
              // justifyContent: 'center',
              // width: '100px'
            },
             
          },
        {
            name: "מספר אשכול",
            grow: 1,
            cell: (row) => row?.qualificationDay?.cluster?.number,
            style: {
              // fontSize: '16px',
              // justifyContent: 'center',
              // width: '100px'
            },
             
          },
    
      ];

      const customStyles = {
        // header: {
        //     style: {
        //         justifyContent: 'center',

        //     },
        // },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        // headRow: {
        //     style: {
              
        //         justifyContent: 'center',
        //         borderTopStyle: 'solid',
        //         borderTopWidth: '1px',fontSize: '14px',
                
        //         // borderTopColor: defaultThemes.default.divider.default,
        //     },
        // },
        headCells: {
            style: {

                fontSize: '14px',    
                
                borderRightStyle: 'solid',
                borderRightWidth: '1px',

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
                justifyContent: 'center',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        }, }

  return (
    <div style={{direction:"ltr"}} >
        <HeaderTable active={"ימי כשירות"}/>
             <ReDataTable columns={columns} getDataTable={getDataTable} fetchData={fetchData} loading={loading} totalRows={totalRows} customStyles={customStyles} />
             {/* {modalIsOpen=="New"&&<NewActivityday handleColse={handleColse}>

             </NewActivityday>} */}
        </div>
  )
}

export default MembersTrainingdays