import React, { useEffect, useState } from 'react'
import { MdOutlineAccessTime } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Rectangle from '../../util/Rectangle.png'
import RegistrationCourse from './RegistrationCourse';
import PaymentModal from './PaymentModal';
import SuccessAnimation from './SuccessAnimation';
import PaymentFailure from './PaymentFailure';
import { convertToIsraelTime } from '../../util/formatsForDate';
import { useLocation } from 'react-router-dom';
const Post = ({course,key,Fromlink="",getTrainingDays}) => {
    const [currentStep, setCurrentStep] = useState(Fromlink);
    const [errMess, setErrMess] = useState("");



    const location = useLocation();
    // const history = useHistory(); // שימוש ב-history לשלוח לדף אחר
  
    useEffect(() => {
      // שליפת פרמטרים מה-URL
      const queryParams = new URLSearchParams(location.search);
      const responseCode = queryParams.get('ResponseCode');
      // console.log("sd")
      // אם יש קוד שגיאה, נעבד את השגיאה
      if (Fromlink&&responseCode) {
        
        const errorMessage = getErrorMessage(responseCode);
        setErrMess(errorMessage);
        setCurrentStep("error")
  
        // אם יש שגיאה, נשלח את המשתמש לדף כישלון
        // if (responseCode === "60000004") {
        //   setTimeout(() => {
        //     history.push('/payment-failure'); // דף כישלון בתשלום
        //   }, 3000); // מחכים 3 שניות לפני המעבר לדף אחר
        // }
      }
    }, []); // מתעדכן אם ה-URL משתנה
  
    // פונקציה שמחזירה הודעת שגיאה לפי ה-ResponseCode
    const getErrorMessage = (responseCode) => {
      switch (responseCode) {
        case "60000001":
          return "הכרטיס חסום לשימוש.";
        case "60000002":
          return "הכרטיס לא תקף.";
        case "60000003":
          return "עסקה לא אושרה על ידי הבנק.";
        case "60000004":
          return "פרטי כרטיס שגויים.";
        case "60000005":
          return "הכרטיס לא תואם לעסקה.";
        case "60000006":
          return "חיבור לרשת לא זמין.";
        case "60000007":
          return "זמן עסקה עבר.";
        case "60000008":
          return "מסגרת אשראי לא מספיקה.";
        default:
          return "שגיאה לא ידועה. אנא נסה שוב.";
      }
    };








  return (
    <div className='w-full flex flex-col shadow-lg rounded-lg p-1 border border-gray-200  '  key={key}
    // onClick={setModalIsOpen}
    >
        <div className='w-full flex flex-col '     onClick={()=>setCurrentStep("popup")}>
        <img src={course?.mySelfQualificationDto?.linkImage?`https://archivebureau.s3.eu-west-3.amazonaws.com/${course?.mySelfQualificationDto?.linkImage}` : Rectangle} alt="" className='rounded-t-lg' />
        {/* <h1 className='font-semibold text-sm text-black '>רשימת המתנה לפוליסת האחריות המקצועית של לשכת ממוני הבטיחות</h1> */}
        <h1 className='font-semibold text-sm text-black ' style={{ direction: "rtl" }}>{course?.mySelfQualificationDto?.name}</h1>
        <div className='flex items-center justify-end pr-3'>
          
            {/* <span className='text-[#595959] text-[1rem]'>11:30 am - 5:00 pm</span> */}
            <span className='text-[#595959] text-[1rem] my-1'>{convertToIsraelTime(course?.mySelfQualificationDto?.startDate)}</span>
            <MdOutlineAccessTime/>
        </div>
        <div className='flex justify-end pr-3 text-right'>
        {/* <span>A5 Villa, Kochi</span> */}
            <span>{course?.mySelfQualificationDto?.place}</span>
            <CiLocationOn/>
        </div>
        </div>
        {(currentStep==="popup") &&<RegistrationCourse course={course} onClose={()=>setCurrentStep("")} onNext={() => setCurrentStep("payment")}/>} 
        {(currentStep==="payment") &&<PaymentModal onClose={()=>setCurrentStep("")} idCorse={course?.mySelfQualificationDto?.id} onBack={() => setCurrentStep("popup")} onNext={(ErrMess) => {setCurrentStep("success")
          setErrMess(ErrMess)}
        } getTrainingDays={getTrainingDays}
        course={course} 
        onError={(ErrMess) => {setCurrentStep("error")
     setErrMess(ErrMess)}} />} 
        {(currentStep==="success") &&<SuccessAnimation onNext={() => setCurrentStep("")} getTrainingDays={getTrainingDays} titlerror={errMess} />} 
        {(currentStep==="error") &&<PaymentFailure onClose={() => setCurrentStep("") } error={errMess} titlerror={"התשלום נכשל"}/>} 
    </div>
  )
}

export default Post