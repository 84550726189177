import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";

import ModalEdit from '../ModalEdit';
import ModalNewClass from './ModalNewClass';
import { HeaderTable } from '../HeaderTable';
const GET_CLASS_URL = 'admin/setting/department'



const Class = () => {

  const [modalIsOpen, setModalIsOpen] = useState("");
  const [id, setId] = useState("");
  const [listClass, setlistClass] = useState([])
  const [loading, setloading] = useState(true)

  const handleColse = () => {
    setModalIsOpen("")
  }

  const handleDeteleClass = async () => {

    await axios.delete(`${GET_CLASS_URL}/${id.id}`).then(response => {
      if (response.status == 200) {
        handleColse()
        getDepartment()
        console.log("delete")
      }
    })
  }


  const getDepartment = async () => {

    await axios.get(GET_CLASS_URL).then(response => {
      setlistClass(response?.data)
    })
    setloading(false)
  }

  useEffect(() => {
    getDepartment()
  }, [])




  const columns = [
    {
      name: "פעולה",

      cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
        // setnamecalss(row?.description)
        // setAction("edit")
        setId(row)
        setModalIsOpen("Edit")
      }
      }><BsPencil /></button>
        <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
          setId(row)
          setModalIsOpen("Delete")
        }}><IoTrashOutline /></button> </div>,
      //handleDeteleClass(row?.id)
      style: {
        fontSize: '16px',
        justifyContent: 'center',

      },
      grow: 1,


    },
    {
      name: "שם מחלקה",
      grow: 6,
      selector: (row) => row.description,
      style: {
        fontSize: '16px',
        justifyContent: 'center',
        width: '100px'
      },
     
    },



  ];
  const customStyles = {
    header: {
      style: {

        justifyContent: 'center',

      },
    },
    id: {
      style: {
        '&:2': { backgroundColor: '#EEEEEE' },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        // borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {

        justifyContent: 'center',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        // borderRightColor: defaultThemes.default.divider.default,

      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {

          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFFFFF',
        '&:active': {
          backgroundColor: '#EEEEEE',
        },
      },
    },
  }

  useEffect(() => {
    if (modalIsOpen) {
        document.body.style.overflow = 'hidden'; // Disable body scrolling
      } else {
        document.body.style.overflow = 'auto'; // Enable body scrolling
      }
  
      return () => {
        document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
      };
    // setHide(modalIsOpen)
}, [modalIsOpen])


  return (
    <div className='w-full p-3'>
      <HeaderTable active={"מחלקות"} setModalIsOpen={setModalIsOpen} />
      <DataTable columns={columns} data={listClass} pagination customStyles={customStyles} progressPending={loading} className='border' >
      </DataTable>
      {modalIsOpen=="New" && <ModalNewClass showModal={modalIsOpen=="New"} handleColse={handleColse} getDepartment={getDepartment}>
      </ModalNewClass>}
      {modalIsOpen=="Edit" && <ModalNewClass showModal={modalIsOpen=="Edit"} handleColse={handleColse} getDepartment={getDepartment} row={id}>
      </ModalNewClass>}
      {modalIsOpen=="Delete" && <ModalEdit showModal={modalIsOpen=="Delete"} handleColse={handleColse} handleDetele={handleDeteleClass}></ModalEdit>}
    </div>
  )
}

export default Class