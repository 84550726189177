import React, { useEffect, useState } from 'react'
import './C.css';
import axios from 'axios';


const UserProfile = ({ userName, avatarUrl }) =>{
  const [dataProfile, setDataProfile] = useState({
          "userName": "",
          "avatarUrl": ""})
  const getDataFILTER = async () => {

    await axios.get("dashboard/member/member_self", {
    })
        .then(response => {
          console.log(response?.data?.firstName)
          setDataProfile({userName:`${response?.data?.firstName} ${response?.data?.lastName}`,avatarUrl:response?.data?.imgFacePicture?`https://archivebureau.s3.eu-west-3.amazonaws.com/${response?.data?.imgFacePicture}`:""})
        })
}
useEffect(()=>{
  getDataFILTER()

},[])
    const defaultAvatar = "https://placehold.co/200";

    return (
      <div className="user-profile">
        {/* <img src={"https://via.placeholder.com/150"} alt="Avatar" className="avatar" /> */}
        <img src={dataProfile.avatarUrl || defaultAvatar} alt="Avatar" className="avatar ring-2 ring-[#C5C2C2]" />

        <div className="user-name">שלום,  {dataProfile?.userName?dataProfile?.userName:"אורח"}</div>
      </div>
    );
}

export default UserProfile