import React, { useEffect, useState } from 'react'
import { HeaderTable } from '../../HeaderTable'
import DataTable from 'react-data-table-component'
import { MdCancel } from "react-icons/md";

import axios from 'axios'
import ModalEdit from '../../ModalEdit';
import PopAddPayingMebers from './PopAddPayingMebers';
const Paying_customer_members = ({row}) => {
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
   const GET_URL = `admin/setting/paying_customer/show/${row?.id}/members`
   const GET_URL_DELETE = `admin/setting/paying_customer/detach/${row?.id}/member`

   
   const [modalIsOpen, setModalIsOpen] = useState("");
   const [id, setId] = useState("");

    const getDataTable = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
        //    
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data)
                    // setdataForfilterScrach(response?.data)
                    // setTotalRows(response.data.totalElements);
                }
            })
        setloading(false)

    }


    useEffect(() => {
        getDataTable(currentPage - 1)
    }, [])

    const columns = [

        {
            name: "פעולות",
            // grow: 2,
            selector: (row) =>
            // <MdCancel/>, 
               <button className='w-6 h-6  text-gray-200 flex justify-center items-center rounded-full bg-slate-500 hover:bg-slate-600'
               onClick={() => {
                setId(row)
                setModalIsOpen("Delete")}}> x</button>,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                
                // width: '100px'
            },
            
        },
        {
            name: "מספר נ.ש",
            // grow: 2,
            selector: (row) => row?.nsnumber,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                
                // width: '100px'
            },
            
        },

        {
            name: "ת.ז.",
            cell: row => row?.idNumber,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 2,


        },
        {
            name: "שם משפחה",
            cell: row => row?.lastName,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 2,


        },
        {
            name: "שם פרטי",
            cell: row => row?.firstName,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            //grow: 2,


        },
        {
            name: "מספר חבר",
            cell: row => row?.number,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            grow: 2,


        },



    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }


    const handleColse = () => {
        setModalIsOpen("")
      }
    
      const handleDetele = async () => {
    
        await axios.get(`${GET_URL_DELETE}/${id.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getDataTable(currentPage - 1)
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })}






    return (
        <div className='w-full p-3' style={{direction:"ltr"}}>

            <HeaderTable active={"ממונים הרשומים תחת הלקוח"}setModalIsOpen={setModalIsOpen} titleBtn={ "צרף ממונים חדשים"} ></HeaderTable>

            <DataTable columns={columns} data={fetchData} pagination
                customStyles={customStyles}
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                progressPending={loading} className='border' >

            </DataTable>
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele} ></ModalEdit>}
            {modalIsOpen == "New" && <PopAddPayingMebers showModal={modalIsOpen == "New"} handleColse={handleColse}  getDataTable={getDataTable} row={row}></PopAddPayingMebers>}


        </div>
    
  )
}

export default Paying_customer_members